import { cleanUrlOfRedirectParams } from '@/utils/url'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useCleanUrlOfRedirectParams = () => {
  const router = useRouter()

  useEffect(() => {
    // page load i.e. user loads the app on a page that results in a redirect
    function cleanUrl() {
      const cleanedUrl = cleanUrlOfRedirectParams()

      if (cleanedUrl) {
        window.history.replaceState(
          { ...window.history.state, as: cleanedUrl, url: cleanedUrl },
          '',
          cleanedUrl
        )
      }
    }

    cleanUrl()

    // client navigation i.e. user clicks a link that results in a redirect
    router.events.on('routeChangeComplete', cleanUrl)

    return () => {
      router.events.off('routeChangeComplete', cleanUrl)
    }
  }, [router])
}
