import { useUser } from '@/services/User/hooks'
import { useEffect } from 'react'
import Bugsnag from '@bugsnag/js'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { setCookie } from '@/utils/cookies'
import { UNIVERSAL_ID } from '@shared/constants/segment'

export const useMonitorUser = () => {
  const user = useUser()
  const { id, universal_id, has_placed_first_order, has_purchased } = user ?? {}

  useEffect(() => {
    if (!id || !universal_id) return

    window.dtrum?.identifyUser(String(id))

    Bugsnag.setUser(String(id))
    Bugsnag.addMetadata('user', {
      anonymousId: getAnonymousId(),
      universalId: universal_id,
      hasPlacedFirstOrder: has_placed_first_order,
      hasPurchased: has_purchased,
    })
    // set universal_id cookie once per user
    setCookie(UNIVERSAL_ID, universal_id, { expires: 365 })
  }, [id, universal_id, has_placed_first_order, has_purchased])
}
