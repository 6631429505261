import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { fontWeights } from '@/styles/typography/constants'
import { BaseText } from '@/styles/typography/base.styled'

const { semibold } = fontWeights

const TitleBase = styled(BaseText)`
  font-weight: ${semibold};
  margin: 0;
`
/**
 * @deprecated Use { Headline } from '@shipt/design-system-typography' instead.
 * NOTE: If the use of this component is NOT for a heading, we want to use `<Body strong />` instead.
 */
export const TagsTitle1 = styled(TitleBase)`
  line-height: 1.125rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
`
/**
 * @deprecated Use { Headline } from '@shipt/design-system-typography' instead
 */
export const Title0 = styled(TitleBase)`
  line-height: 2.625rem;
  font-size: 2rem;
`
/**
 * @deprecated Use { Headline } from '@shipt/design-system-typography' instead
 */
export const Title1 = styled(TitleBase)`
  line-height: 2rem;
  font-size: 1.5rem;
`
/**
 * @deprecated Use { Headline } from '@shipt/design-system-typography' instead
 */
export const Title2 = styled(TitleBase)`
  line-height: 1.5rem;
  font-size: 1.1875rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 2rem;
      font-size: 1.5rem;
    }
  `};
`
/**
 * @deprecated Use { Headline } from '@shipt/design-system-typography' instead
 */
export const Title3 = styled(TitleBase)`
  line-height: 1.125rem;
  font-size: 0.75rem;
`
