import { isOnServer } from '@shared/constants/util'
import throttle from 'lodash/throttle'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

type ChangeHandler = (path: string, params: { shallow: boolean }) => void

/**
 * largely inspired by this post - https://jak-ch-ll.medium.com/next-js-preserve-scroll-history-334cf699802a
 * - PDP scroll is wonky - it adds 60 or so pixels each navigation back
 * - SSR'ed product grids (buy_again, lists) are wonky as we're performing another fetch on the server, so order changes
 * -known limitation is scroll restoration is not preserved across sessions/reloads. This is a challenge as we'd also need
 * to cache/localStorage react query results if we want this to work.
 * - some scroll restore is affected by lazy-rendered shelves that are above part of the page that we navigate back to. This is subtle
 * and isn't _too_ terrible. e.g Product descriptions lazy load in which push the page down
 * - depending on where a product shelf is, on scroll restoration, the shelf will not be visible until you scroll up. We'll need to tweak
 * how product shelves render
 */

if (!isOnServer()) {
  // @ts-expect-error -- indexing window
  window['__LOG_SCROLL_RESTORE_ACTIVITY'] = false

  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual'
  }
}

const scrollState: Record<string, number> = {}
function shouldLog() {
  // @ts-expect-error -- indexing window object
  return !!window['__LOG_SCROLL_RESTORE_ACTIVITY']
}

const debouncedScrollState = throttle(function scrollListener() {
  const fullPath = `${location.pathname}${location.search}`
  scrollState[fullPath] = scrollY
  if (shouldLog()) {
    // eslint-disable-next-line no-console -- its fine
    console.log(`capturing scroll state ${fullPath}, ${scrollY}`)
  }
}, 200)

export const useScrollRestoration = () => {
  const router = useRouter()
  const isBack = useRef<boolean>(false)

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollState)
    return () => {
      window.removeEventListener('scroll', debouncedScrollState)
    }
  }, [])

  useEffect(() => {
    const onRouteComplete: ChangeHandler = (path) => {
      const pageScrollState = scrollState[path]

      if (pageScrollState && isBack.current) {
        if (shouldLog()) {
          // eslint-disable-next-line no-console -- shup
          console.log(`SCROLLING TO ${pageScrollState}`)
        }
        scrollTo({
          top: pageScrollState,
          behavior: 'instant',
        })
      }

      isBack.current = false
    }

    router.beforePopState(() => {
      isBack.current = true
      return true
    })
    router.events.on('routeChangeComplete', onRouteComplete)

    return () => {
      router.events.off('routeChangeComplete', onRouteComplete)
    }
  }, [router])

  return null
}
