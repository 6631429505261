export enum PromotionTypes {
  OrderDiscount = 'next-order-discount',
  DeliveryFeeMember = 'delivery-fee-member',
  DeliveryFeeNonMember = 'delivery-fee-non-member',
  SpendGet = 'spend_get',
  Membership = 'membership',
}

export enum PromotionAudienceTypes {
  Global = 'global',
  Targeted = 'targeted',
}

export type AvailablePromotionsResponse = {
  promotions: Maybe<Promotion[]>
}

export type AvailablePromotionsPayload = {
  availablePromotions: Promotion[]
  dollarTotalQualifyingCredit: number
}

export type PromotionThreshold = {
  progress?: Maybe<number>
  remaining_amount?: Maybe<number>
  threshold_amount?: Maybe<number>
  threshold_type?: Maybe<string>
  effect_currency?: Maybe<string>
  effect_type?: Maybe<string>
  effect?: Maybe<number>
}

export type Promotion = {
  id?: Maybe<number> // campaigns id for tracking
  audience?: Maybe<PromotionAudienceTypes>
  promotion_id?: Maybe<string> //uuid of promotion
  type?: Maybe<PromotionTypes>
  display_name?: Maybe<string>
  display_description?: Maybe<string>
  disclaimer_body?: Maybe<string>
  disclaimer_header?: Maybe<string>
  full_terms_title?: Maybe<string>
  full_terms_url?: Maybe<string>
  terms?: Maybe<string>
  valid_through?: Maybe<string>
  valid_through_string?: Maybe<string>
  thresholds?: Maybe<PromotionThreshold[]>
  promotion_params?: Maybe<PromotionParams>
  member_only?: boolean
  available_stores?: Maybe<string[]>
  offer_headline?: Maybe<string>
  button_cta?: Maybe<string>
  display_custom_copy?: Maybe<string>
  progress_header?: Maybe<string>
  progress_subheader?: Maybe<string>
  progress?: Maybe<number>
}

export type PromotionParams = {
  category_id?: Maybe<number>
  id?: Maybe<number> // internal table name
  images?: Maybe<{ image_url: string }[]>
  is_marketing_category?: boolean
  position?: Maybe<number>
  ui_promotion_type?: Maybe<string>
  feature_promotion_group_id?: Maybe<number>
  original_price?: Maybe<number>
  hide_original_price?: Maybe<boolean>
  search_url?: Maybe<string>
  landing_page_id?: Maybe<string>
  relative_link?: Maybe<string>
}

export type OrderPromotions = {
  promotion_key?: Maybe<string>
  credit?: Maybe<number>
  context?: Maybe<string>
  display_description?: Maybe<string>
  display_name?: Maybe<string>
  full_terms_title?: Maybe<string>
  full_terms_url?: Maybe<string>
  thresholds?: Maybe<string>
  type?: Maybe<string>
  valid_through?: Maybe<string>
  valid_through_string?: Maybe<string>
  image_url?: Maybe<string>
  id?: Maybe<number>
  progress?: Maybe<number>
  progress_header?: Maybe<string>
  progress_subheader?: Maybe<string>
}
