import ServerCookies from 'cookies'
import { insecureCookieOptions } from '@shared/constants/auth'
import {
  type NextApiRequest,
  type NextApiResponse,
  type GetServerSidePropsContext,
} from 'next'
import { GuestDataStore } from '@/constants/global'
import { environment } from '@/utils/environment'
import { GUEST_ADDRESS_REMOVAL_FLOW } from '@/components/AddressRemoval/useAddressRemovalExperiment'

const guestSSRCookieOptions = {
  ...insecureCookieOptions,
  domain: undefined, // Exclude the domain option to be consistent with the client cookie setter
  maxAge: 7 * 24 * 60 * 60 * 1000, // 7 days
}

export const setServerGuestCookie = <TValue>({
  name,
  value,
  ssrContext,
}: {
  name: string
  value: TValue
  ssrContext: GetServerSidePropsContext
}) => {
  const cookies = new ServerCookies(ssrContext.req, ssrContext.res)
  const stringifiedValue = JSON.stringify(value)
  ssrContext.req.cookies[name] = stringifiedValue
  cookies.set(name, encodeURIComponent(stringifiedValue), guestSSRCookieOptions)
}

export const removeServerGuestCookies = (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const cookies = new ServerCookies(req, res)
  const cookieKeys = [
    GuestDataStore.ADDRESS,
    GuestDataStore.STORE,
    GUEST_ADDRESS_REMOVAL_FLOW,
  ]
  cookieKeys.forEach((key) => {
    // Only expire cookies that exist to limit Set-Cookie headers
    if (req.cookies[key]) {
      cookies.set(key, null, {
        expires: new Date(0),
        secure: environment() !== 'local',
      })
    }
  })
}
