import { type SignUpError } from '@/services/User/types'
import { useForm } from 'react-hook-form'
import { emailValidation } from '@/utils/validations'
import { type ShoppingStoreDetails } from '@/services/ShoppingStore/types'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

export const isValidGuestStore = (
  store?: Maybe<ShoppingStoreDetails>
): store is ShoppingStoreDetails => {
  const { store_location_id, id, metro_id } = store ?? {}
  return Boolean(store_location_id && id && metro_id)
}

export const nameValidation = z.string().trim().min(1, 'Full name is required')

export const phoneValidation = z
  .string()
  .min(1, 'Phone number is required')
  .refine((val) => val?.length === 10, {
    message: 'Phone number must be 10 digits',
  })

export type SignupFormData = {
  fullName: string
  email: string
  password: string
  confirmPassword: string
  promoCode?: string
}

export const signupValidationSchema: z.Schema<SignupFormData> = z
  .object({
    fullName: nameValidation,
    email: emailValidation,
    password: z.string().min(1),
    confirmPassword: z.string().min(1),
    promoCode: z.string().optional(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'Passwords do not match',
        path: ['confirmPassword'],
      })
    }
  })

export const useSignupForm = () => {
  const {
    handleSubmit,
    control,
    resetField,
    setError,
    formState: { errors },
  } = useForm<SignupFormData>({
    resolver: zodResolver(signupValidationSchema),
  })

  const handleSignupError = (error: SignUpError) => {
    error.details?.username
      ? setError(
          'email',
          {
            type: 'validate',
            message: error.details['username'],
          },
          { shouldFocus: true }
        )
      : null

    error.details?.name
      ? setError(
          'fullName',
          {
            type: 'validate',
            message: error.details['name'],
          },
          { shouldFocus: true }
        )
      : null

    error.details?.password
      ? setError(
          'password',
          {
            type: 'validate',
            message: error.details['password'],
          },
          { shouldFocus: true }
        )
      : null
  }

  const handlePromoCodeErrorMessage = (errorMessage: string) => {
    setError(
      'promoCode',
      {
        type: 'validate',
        message: errorMessage,
      },
      { shouldFocus: true }
    )
  }

  return {
    handleSubmit,
    control,
    resetField,
    setError,
    formState: { errors },
    handleSignupError,
    handlePromoCodeErrorMessage,
  }
}
