import dynamic from 'next/dynamic'
import { marketingPaths } from '@shared/constants/routes'
import { useIsOneOfPages } from '@/hooks/useIsPage'

const Footer = dynamic(
  () => import('@/components/Footers/Footer').then((mod) => mod.Footer),
  { ssr: false }
)

export const AppFooter = () => {
  const isMarketingPage = useIsOneOfPages(marketingPaths)

  if (isMarketingPage) {
    return null
  }

  return <Footer />
}
