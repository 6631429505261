import { isKeyCode } from '@/utils/keyEvents'

export const detectKeyboardUser = () => {
  const handleFirstTab = (e: KeyboardEvent) => {
    if (isKeyCode(e, 'Tab')) {
      document.body.classList.add('is-keyboarduser')

      window.removeEventListener('keydown', handleFirstTab)
      window.addEventListener('mousedown', handleMouseDownOnce)
    }
  }

  const handleMouseDownOnce = () => {
    document.body.classList.remove('is-keyboarduser')

    window.removeEventListener('mousedown', handleMouseDownOnce)
    window.addEventListener('keydown', (e) => handleFirstTab(e))
  }

  window.addEventListener('keydown', (e) => handleFirstTab(e))
}

export const isKeyboardUser = () => {
  return document.body.classList.contains('is-keyboarduser')
}
