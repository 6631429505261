import {
  type ElevationVariantOption,
  type ShadowContextOption,
} from '@shipt/design-system-themes'
import { css } from 'styled-components'

export const overlayGradient =
  'linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, white 25%)'

export const boxShadow = `0 0 0.875rem 0.25rem rgba(0, 0, 0, 0.08)`

export const shadow = `
  transition: box-shadow 0.25s ease;
  box-shadow: ${boxShadow};
`

export const hoverShadow = `
  :hover {
    box-shadow: 0 0 0.875rem 0.25rem rgba(0, 0, 0, 0.11);
  }
`

export const shadow1 = `
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.2);
`
export const shadow2 = `
  box-shadow: 0 0 0.6875rem 0 rgba(0, 0, 0, 0.2);
`

export const boxShadow5 = `0 0 0.25rem 0 rgba(0, 0, 0, 0.08);`
export const boxShadowTransition = `box-shadow 0.25s ease;`
export const boxShadowFocus = `0.125rem 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.08), 0 0 0.25rem 0 rgba(0, 0, 0, 0.08);`

export const shadow5 = css`
  box-shadow: ${boxShadow5};
  transition: ${boxShadowTransition};

  &:hover,
  &:active,
  &:focus {
    box-shadow: ${boxShadowFocus};
  }
`

export const shadow7 = `
  box-shadow: 0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);
`
export const BasicShadow = `
box-shadow: 0rem 0.125rem 0.5rem 0rem #180C2033;
`
export const BasicShadowHover = `
box-shadow: 0rem 0.25rem 1rem 0.25rem #180C201A;
`
export const ColorCompShadow = `
box-shadow: 0rem 0.125rem 0.25rem 0rem #180C2033;
`

export const getBoxShadow = (
  surface: ElevationVariantOption,
  state: ShadowContextOption,
  hover = true
) => css`
  box-shadow: ${({ theme }) =>
    theme.shadow[surface][state].default.value.reduce(
      (acc, curr, i) =>
        acc +
        `${i > 0 ? ', ' : ''}${curr.x}px ${curr.y}px ${curr.blur}px ${
          curr.spread
        }px ${curr.color}`,
      ''
    ) || null};

  ${({ theme }) =>
    hover &&
    css`
      &:hover {
        box-shadow: ${theme.shadow[surface][state].hover.value.reduce(
          (acc, curr, i) =>
            acc +
            `${i > 0 ? ', ' : ''}${curr.x}px ${curr.y}px ${curr.blur}px ${
              curr.spread
            }px ${curr.color}`,
          ''
        ) || null};
      }
    `};
`
