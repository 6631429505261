import { useCallback, useEffect } from 'react'
import { type ExperimentType } from '@/services/Experiments/constants'
import { type ExperimentValue } from '@/services/Experiments/types'
import { useMutationTrackExperiment } from '@/services/Experiments/mutations'
import { type RouteName, routes } from '@shared/constants/routes'
import { useAtomValue } from 'jotai'
import { experimentsAtom } from '@/state/Experiments/atoms'
import { getExperimentStatus } from '@/services/Experiments/utils'

/**
 * Hooks for use with Aristotle's experiment data
 */

export type UseExperiment = {
  /**
   * Call `track()` whenever the member is shown the control or variation, not
   * when they interact with the experiment variation.
   */
  track: () => void

  /**
   * When active is true, show member the experiment variation. When active is
   * false, show member the experiment control.
   */
  active: boolean

  /**
   * Returns true if experiments are being loaded
   */
  isPending: boolean

  /**
   * Returns true if the experiments have been fetched
   */
  isFetched?: boolean

  /**
   * Returns true if experiments have been successfully fetched
   */
  isSuccess?: boolean

  /**
   * When active is true value provides whether the user is in 'control', 'variant', 'variant_1', 'variant_2' etc.
   */
  value?: ExperimentValue

  /**
   * Call `track()` automatically if `autoTrack` is true
   */
  autoTrack?: boolean
}

export const useExperiment = (
  key: ExperimentType,
  { autoTrack = false } = {}
): UseExperiment => {
  const {
    data: experiments,
    isPending,
    isSuccess,
    isFetched,
  } = useAtomValue(experimentsAtom)
  const { value, active } = getExperimentStatus(experiments, key)
  const { mutate: trackExperiment } = useMutationTrackExperiment(key)

  const track = useCallback(() => {
    // re-create this hook once the data from aristotle is loaded
    if (!value) return
    trackExperiment(value)
  }, [value, trackExperiment])

  useEffect(() => {
    if (autoTrack) track()
  }, [autoTrack, track])

  return {
    active,
    track,
    value,
    isPending,
    isSuccess,
    isFetched,
  }
}

export const useMultiVariantExperiment = (experimentName: ExperimentType) => {
  const { active, isPending, track, value, isSuccess } =
    useExperiment(experimentName)

  const variant1 = value === 'variation_1'
  const variant2 = value === 'variation_2'
  const variant3 = value === 'variation_3'

  return { active, variant1, variant2, variant3, isPending, track, isSuccess }
}

export const useSSRQueryString = (
  experiment: ExperimentType,
  routeName: RouteName
) => {
  const route = routes[routeName].url
  const { active } = useExperiment(experiment)
  return active ? `${route}?ssr=true` : route
}
