import { shouldDisableAnalytics } from '@/analytics/utils'
import { isOnServer } from '@shared/constants/util'
import {
  type CSCustomVariable,
  type CSDynamicVariable,
  type CSInstruction,
  type CSSetQueryValue,
  type CSTrackStoreParamKeys,
  type CSTrackStoreParams,
} from '@/lib/contentsquare/types'
import { getObjectEntries, getObjectKeys } from '@/utils/types'

declare global {
  interface Window {
    _uxa?: CSInstruction[] | { push: (instruction: CSInstruction) => void }
  }
}

const isTagIdConfigured = () => !!process.env.NEXT_PUBLIC_CONTENTSQUARE_TAG_ID

/**
 * DO NOT EXPORT this function, instead export a controlled instruction helper
 * function in order to meet the correct needs. In some cases we need
 * consistent index values, e.g. Custom Vars; thus, we need to have
 * strict control over how we set the index values.
 */
function pushCSInstruction(instruction: CSInstruction): boolean {
  if (!isTagIdConfigured() || isOnServer() || shouldDisableAnalytics()) {
    return false
  }
  try {
    /** @see https://docs.contentsquare.com/en/web/#the-_uxa-object */
    window._uxa = window?._uxa || []
    window?._uxa?.push?.(instruction)
    return true
  } catch {
    // continue regardless of error
    return false
  }
}

/**
 * @see https://docs.contentsquare.com/en/web/ecommerce-tag/#custom-html
 */
export function csECommerceTag(id: number, revenue: number): void {
  if (
    pushCSInstruction([
      'ec:transaction:create',
      {
        id: String(id),
        revenue: String(revenue),
        currency: 'USD',
      },
    ])
  ) {
    pushCSInstruction(['ec:transaction:send'])
  }
}

/**
 * @see https://docs.contentsquare.com/en/web/personal-data-handling/#blocking-personal-data-in-urls
 */
export function csSetQuery(query: CSSetQueryValue): void {
  pushCSInstruction(['setQuery', query])
}

// NOTE: Please update src/lib/ThirdPartyScripts/scripts.ts if changed
export const CSMaskQueryStrings = {
  access_token: 'CS_ANONYMIZED_ACCESS_TOKEN',
  address: 'CS_ANONYMIZED_ADDRESS',
  // Yes, these are uppercase, see src/hooks/useSocialSignOnParams.ts
  ID: 'CS_ANONYMIZED_ID',
  FirstName: 'CS_ANONYMIZED_FIRST_NAME',
  LastName: 'CS_ANONYMIZED_LAST_NAME',
  name: 'CS_ANONYMIZED_NAME',
  password: 'CS_ANONYMIZED_PASSWORD',
  provider: 'CS_ANONYMIZED_PROVIDER',
  refresh_token: 'CS_ANONYMIZED_REFRESH_TOKEN',
  token_type: 'CS_ANONYMIZED_TOKEN_TYPE',
  zip: 'CS_ANONYMIZED_ZIP',
  zipcode: 'CS_ANONYMIZED_ZIPCODE',
} as const

// NOTE: Please update src/lib/ThirdPartyScripts/scripts.ts if changed
export function csMaskKnownQueryStrings(search: string): void {
  if (!search) {
    return
  }
  const searchParams = new URLSearchParams(search)
  const size = Array.from(searchParams).length
  if (size === 0) {
    return
  }
  Object.entries(CSMaskQueryStrings).forEach(([key, maskValue]) => {
    if (searchParams.has(key)) {
      searchParams.set(key, maskValue)
    }
  })
  csSetQuery(`?${searchParams.toString()}`)
}

/**
 * @see https://docs.contentsquare.com/en/webview-tracking-web-tag/track-pageviews/#overview
 */
export function csTrackPageview(pathName: string, search: string): void {
  csMaskKnownQueryStrings(search)
  pushCSInstruction(['trackPageview', pathName])
}

/**
 * DO NOT EXPORT this function, instead use csSetStoreParams or csDeleteStoreParams
 * If these are not meeting the needs of the business requirements,
 * carefully call this function with a new csHelperFunc and
 * MAKE SURE indexes do not collide with existing cVars.
 *
 * @see https://docs.contentsquare.com/en/web/sending-custom-vars
 *
 */
function setCustomVar(params: CSCustomVariable): void {
  if (!params.scope) {
    pushCSInstruction([
      'setCustomVariable',
      params.index,
      params.name,
      params.value,
    ])
  } else {
    pushCSInstruction([
      'setCustomVariable',
      params.index,
      params.name,
      params.value,
      params.scope,
    ])
  }
}

/**
 * @see https://docs.contentsquare.com/en/web/sending-dynamic-vars/
 *
 * NOTE: rename to csSetDynamicVar if function is exported.
 */
function setDynamicVar(params: CSDynamicVariable): void {
  pushCSInstruction(['trackDynamicVariable', params])
}

/**
 * From the Docs:
 * Use a consistent index for a given custom var within a project — for instance,
 * if the “page template” is collected with an index of 3, use the slot 3
 * for this information on every page of the website.
 */
export const CSCustomVarIndexes: Record<
  CSTrackStoreParamKeys,
  CSCustomVariable['index']
> = {
  user_id: 1,
  metro_id: 2,
  store_id: 3,
  store_location_id: 4,
  zip: 5,
}
// Will see if this is wanted or not, otherwise this would be how I would
// expect us to track the next set of indexes starting up from 6, aka using 6-20
// const ExtraVarIndexes: Record<string, CSCustomVariable['index']> = {
//   is_guess: 6,
// }

/**
 * From the Docs:
 * A custom var can be deleted by calling the command with an empty string
 * value. The index parameter must match the custom var to delete.
 */
const DELETE_CUSTOM_VAR_VALUE = ''

function deleteCustomVar(name: CSTrackStoreParamKeys): void {
  setCustomVar({
    index: CSCustomVarIndexes[name],
    name,
    value: DELETE_CUSTOM_VAR_VALUE,
  })
}

export function csDeleteStoreParams(): void {
  getObjectKeys(CSCustomVarIndexes).forEach(deleteCustomVar)
}

const shouldDeleteCustomVar = (
  name: CSTrackStoreParamKeys,
  previousStoreParams: undefined | CSTrackStoreParams
) => {
  const previousValue = previousStoreParams?.[name]
  return (
    previousValue !== null &&
    typeof previousValue !== 'undefined' &&
    ((typeof previousValue === 'string' &&
      previousValue !== DELETE_CUSTOM_VAR_VALUE) ||
      previousValue !== 0)
  )
}

export function csSetStoreParams(
  params: CSTrackStoreParams,
  options?: { previousStoreParams: CSTrackStoreParams }
): void {
  const storeParamEntries = getObjectEntries(params)
  for (const [name, param] of storeParamEntries) {
    let value: string = DELETE_CUSTOM_VAR_VALUE
    if (typeof param === 'string' && param) {
      value = param
    } else if (typeof param === 'number' && param !== 0) {
      value = param.toString()
    }
    if (value === DELETE_CUSTOM_VAR_VALUE) {
      if (shouldDeleteCustomVar(name, options?.previousStoreParams)) {
        deleteCustomVar(name)
      }
      continue
    }
    setDynamicVar({ key: name, value })
    setCustomVar({
      index: CSCustomVarIndexes[name],
      name,
      value,
    })
  }
}
