import { css } from 'styled-components'

export const overflowTouch = `
  -webkit-overflow-scrolling: touch;
`

export const hideScrollBar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
