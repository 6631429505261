import { queryOptions, useQuery } from '@tanstack/react-query'
import {
  type StoreParamsAsKey,
  useStoreParams,
} from '@/utils/dataFetching/storeParams'
import { minutesToMilliseconds } from 'date-fns'
import { type SignupPlansPayload } from '@/services/DeliveryPlans/types'
import {
  fetchDeliveryPlans,
  fetchSignupPlans,
} from '@/services/DeliveryPlans/fetcher'
import { useSignupPlansQueryKeyParams } from '@/services/DeliveryPlans/hooks'

/**
 * useQueryDeliveryPlans
 */
export const DeliveryPlansQuery = 'DeliveryPlans'

const getDeliveryPlansQueryKey = ({ storeParams }: StoreParamsAsKey) =>
  [DeliveryPlansQuery, { storeParams }] as const

export type DeliveryPlanQueryKey = ReturnType<typeof getDeliveryPlansQueryKey>

const getDeliveryPlansQueryOptions = () => ({
  staleTime: minutesToMilliseconds(15),
})

export const useQueryDeliveryPlans = () => {
  const storeParams = useStoreParams()

  return useQuery({
    queryKey: getDeliveryPlansQueryKey({
      storeParams,
    }),
    queryFn: fetchDeliveryPlans,
    ...getDeliveryPlansQueryOptions(),
    enabled: Boolean(storeParams.user_id && storeParams.metro_id),
  })
}
useQueryDeliveryPlans.getKey = getDeliveryPlansQueryKey
useQueryDeliveryPlans.fetcher = fetchDeliveryPlans
useQueryDeliveryPlans.options = getDeliveryPlansQueryOptions

/**
 * useFetchDeliveryPlans
 */
export const SignupPlans = 'Signup plans'

export const getSignupPlansQueryKey = (params: SignupPlansPayload) =>
  [SignupPlans, { ...params }] as const

export type SignupPlansKey = ReturnType<typeof getSignupPlansQueryKey>

export const signupPlansQueryOptions = (params: SignupPlansPayload) =>
  queryOptions({
    queryKey: getSignupPlansQueryKey(params),
    queryFn: fetchSignupPlans,
  })

/**
 * useQuerySignupPlans
 */
export const useQuerySignupPlans = ({
  enabled = false,
}: {
  enabled: boolean
}) => {
  const { getQueryKeyParams } = useSignupPlansQueryKeyParams()
  const params = getQueryKeyParams()

  return useQuery({ ...signupPlansQueryOptions(params), enabled })
}

useQuerySignupPlans.getKey = getSignupPlansQueryKey
useQuerySignupPlans.fetcher = fetchSignupPlans
