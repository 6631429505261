/**
 * @returns An array of session secrets (strings). The first key is always used for
 * session cookie encryption going forward. All keys are used for session cookie
 * decryption (keys are attempted in order). This allows for key rotation.
 */
export function getSessionSecret() {
  // Secrets loaded from GCP secret manager cannot be empty; string 0 was chosen as a sentinel
  if (process.env.AUTH0_SECRET_PREV && process.env.AUTH0_SECRET_PREV !== '0') {
    return [process.env.AUTH0_SECRET, process.env.AUTH0_SECRET_PREV]
  }
  return [process.env.AUTH0_SECRET]
}
