import { Z_INDEX_MAX } from '@/constants/global'
import { createGlobalStyle, css } from 'styled-components'

export const whiteGradient = css`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 255) 100%
  );
`

export const focusStyles = css`
  // Default
  outline-color: black;
  outline-style: dashed;
  outline-width: 1px;
  outline-offset: 1px;
  // Outline Radius - Mozilla Only
  -moz-outline-radius: 4px;
`

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  div,
  article,
  section,
  main,
  footer,
  header,
  form,
  fieldset,
  legend,
  pre,
  code,
  a,
  h1,h2,h3,h4,h5,h6,
  p,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  textarea,
  table,
  td,
  th,
  tr,
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  .border-box {
    box-sizing: border-box;
  }

  img { max-width: 100%; }

  html {
    background-color: ${({ theme }) => theme.white};
  }
  
  html,
  body {
    min-height: 100%;
    width: 100%;
  }
  
  // Extend tachyons positions
  .sticky {
    position: sticky;
  }

  // Use system font stack across the app
  body,
  button,
  input,
  optgroup,
  select,
  textarea {
      font-family: EuclidCircular, system-ui, sans-serif;
      letter-spacing: 0;
  }
  
  // Global styles used for google autocomplete dropdown
  .pac-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 0.25em;
    z-index: ${Z_INDEX_MAX};
  }
  
  .pac-item {
    color: ${({ theme }) => theme.gray600};
    border: none;
    padding: 0.5rem;
  
    // small type
    font-weight: 300;
    line-height: 1.2;
    font-size: 0.9375rem;
    letter-spacing: 0.0125em;
  }
  
  .pac-item-selected {
    background-color: ${({ theme }) => theme.blue100};
  }
  
  .pac-item:hover {
    background-color: rgba(0, 0, 0, 0.025); // --black-025
  }
  
  .pac-item-query {
    color: ${({ theme }) => theme.gray600};
    font-size: 0.9375rem;
  }
  
  .pac-icon {
    display: none;
  }
  
  // Global override for ProductDetailCarousel mobile view
  .swiper {
    .swiper-pagination-bullets {
      bottom: 0 !important;
    }
  
    .swiper-pagination {
      .swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.gray900};
      }
    }
  
    .swiper-zoom-container:hover {
      cursor: zoom-in;
  
      &.is-zooming {
        cursor: zoom-out;
        transform: scale(3);
      }
  
      @media (prefers-reduced-motion: reduce) {
        &,
        &.is-zooming {
          cursor: default;
          transform: none;
        }
      }
    }
  }
  
  // global style for keyboard users
  .is-keyboarduser .hide-keyboarduser {
    display: none;
  }
  a:focus,
  button:focus,
  input:focus,
  textarea:focus,
  select:focus,
  li:focus,
  [tabindex='0']:focus,
  [tabindex='-1']:focus,
  label:focus-within {
    ${focusStyles}
  }

  body:not(.is-keyboarduser) *:focus, 
  body:not(.is-keyboarduser) label:focus-within {
    outline: none;
  }
`
