import { getCookieRaw } from '@/utils/cookies'
import { logWarn } from '@/utils/logger'
import { type ReactNode, createContext, useContext } from 'react'

interface ChallengeValue {
  challengeNonce: string
  prefix: string
  rawString: string
}
export const CHALLENGE_RESPONSE_HEADER = 'x-shipt-challenge-response'
export const CHALLENGE_COOKIE = 'shipt.challenge'
export const LoginChallengeContext = createContext<ChallengeValue | undefined>(
  undefined
)
export function LoginChallengeProvider({ children }: { children: ReactNode }) {
  const challengeValue = getChallengeValue()

  return (
    <LoginChallengeContext.Provider value={challengeValue}>
      {children}
    </LoginChallengeContext.Provider>
  )
}
function getChallengeValue(): ChallengeValue | undefined {
  if (typeof window === 'undefined') return

  const cookieValue = getCookieRaw(CHALLENGE_COOKIE)
  const challengeValue = parseChallengeHeader(cookieValue)

  return challengeValue
}

// example value v=1&k=2023-09-05-1&p=000&s=216.24.170.239&n=9b40a44d-6332-48bb-a847-100b828a5102&h=ikl2X%2BAPx8NqEn8lLGelkOV0oJ%2FlZom%2BN34aw2bmVLs%3D
export function parseChallengeHeader(
  rawString: string | undefined
): ChallengeValue | undefined {
  if (!rawString) return

  const queryParams = new URLSearchParams(rawString)
  const challengeNonce = queryParams.get('n')
  const prefix = queryParams.get('p')

  if (!challengeNonce || !prefix) {
    logWarn('challenge nonce and/or prefix is not in cookie')
  }

  return {
    challengeNonce: challengeNonce ?? '',
    prefix: prefix ?? '',
    rawString,
  }
}
export const useLoginChallengeContext = () => {
  const value = useContext(LoginChallengeContext)

  return value
}
