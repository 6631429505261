import capitalize from 'lodash/capitalize'
import { type PageRouteData } from '@/utils/setPageProps'
import { ShiptStaticData } from '@/constants/shiptStaticData'

export const transformRoutesToTitle = (
  pathname: string,
  routeData: PageRouteData
) => {
  const partnerName = ShiptStaticData.displayName
  try {
    const baseTitle = routeData ? pathname.split('/')[1] : '404'
    const capitalizedBaseTitle = capitalize(baseTitle)
    const title = routeData?.pageTitle || capitalizedBaseTitle
    const partner = routeData?.hidePartnerName ? '' : ` - ${partnerName}`

    return `${title}${partner}`
  } catch (e) {
    return partnerName
  }
}
