import { type ParsedUrlQuery } from 'querystring'

const sort = <TParam extends Record<string, unknown>>(param: TParam) =>
  Object.keys(param)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: param[key],
      }),
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- tell TS this is a TParam by default
      {} as TParam
    )

const extractQueryString = (url: string) => {
  const queryStart = url.indexOf('?')
  if (queryStart === -1) {
    return ''
  }

  return url.slice(queryStart + 1)
}

export const parse = (url: string) => {
  let params: ParsedUrlQuery = {}
  new URLSearchParams(url).forEach((val, key) => {
    const param = params[key]
    if (typeof param !== 'undefined') {
      // If params[key] is an array, we want to add the new value to it
      if (Array.isArray(param)) {
        params = { ...params, [key]: [...param, val] }
      } else {
        params = { ...params, [key]: [param, val] }
      }
    } else {
      params[key] = val
    }
  })
  return params
}

export const parseUrl = (url: string) => {
  const result = parse(extractQueryString(url))
  const query = sort(result)
  return { url: url?.split('?')?.[0] ?? '', query }
}

export const stringify = (obj: Record<string, unknown>) => {
  // Set up a new URLSearchParams object.
  const searchParams = new URLSearchParams()
  const sortedObj = sort(obj)
  // Loop through the params object and append each key/value
  // pair to the URLSearchParams object. To keep array values
  // in same structure to qs lib, must use duplicate keys
  for (const [key, value] of Object.entries(sortedObj)) {
    if (Array.isArray(value)) {
      value.forEach((item) => searchParams.append(key, item))
    } else {
      searchParams.append(key, String(value))
    }

    const param = searchParams.get(key)
    // If a key is undefined, delete it.
    if (param === 'undefined') {
      searchParams.delete(key)
    }
  }

  // Convert the URLSearchParams object to a string and replace + sign
  return searchParams.toString().replace(/\+/g, '%20')
}

export const stringifyWithCatIdsArray = (
  obj: Record<string, unknown>,
  category_ids: string
) =>
  category_ids
    ? `${stringify(obj)}&category_ids=${category_ids}`
    : stringify(obj)
