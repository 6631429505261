import { type PageRouteData } from '@/utils/setPageProps'
import Bugsnag from '@bugsnag/js'

export function setGlobalContextFromRoute(routeData: PageRouteData) {
  const routeName = routeData?.routeName ?? 'UNKNOWN'
  const crew = routeData?.crew ?? 'UNKNOWN'
  Bugsnag.setContext(routeName)
  Bugsnag.addMetadata('page', {
    crew,
    path: window.location.pathname,
    pageGroup: routeName,
  })

  // used in Dynatrace as a custom action property
  // @ts-expect-error indexing window object
  window['__SEGWAY_CREW'] = crew
}
