import { useRouter } from 'next/router'
import { useQueryCouponCode } from '@/services/SignupPromotions/queries'

export const useSignupPromotionsParams = () => {
  const router = useRouter()
  const { zip = '', zipcode = '', gift_code = '' } = router?.query ?? {}
  return {
    zipParam: String(zip || zipcode),
    promoCode: String(gift_code),
  }
}

export const useCouponCode = () => {
  const { data: coupon } = useQueryCouponCode()
  const { code_group, referrer_code = '', credit_amount = 0 } = coupon ?? {}
  const { gives_subscription } = code_group ?? {}

  return {
    coupon,
    promoCode: referrer_code ?? coupon?.code ?? '',
    referrerCredit: referrer_code && !code_group ? credit_amount : 0,
    hasGiftMembership: Boolean(gives_subscription),
  }
}
