import { getHasSignupPromotionPlans } from '@/services/SignupPromotions/utils'
import { useCallback, useState } from 'react'
import { signupPlansQueryOptions } from '@/services/DeliveryPlans/queries'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { fetchCouponCode } from '@/services/SignupPromotions/fetchers'
import { type ParsedUrlQuery } from 'querystring'
import { useGetInitialSignupQueryParams } from '@/services/DeliveryPlans/hooks'
import { type CouponCode } from '@/services/SignupPromotions/types'

export const useCheckEligibleSignupPromotions = (enteredEmail?: string) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { getInitialSignupParams } = useGetInitialSignupQueryParams()

  return useCallback(async () => {
    const coupon = queryClient.getQueryData<CouponCode>(
      getCouponCodeQueryKey(router.query)
    )
    // If a coupon plan is available, it's unnecessary to fetch available plans
    if (coupon?.code_group) return true

    return queryClient
      .fetchQuery(signupPlansQueryOptions(getInitialSignupParams(enteredEmail)))
      .then(getHasSignupPromotionPlans)
      .catch(() => false)
  }, [router.query, queryClient, getInitialSignupParams, enteredEmail])
}

/**
 * useQueryCouponCode
 */
export const CouponCodeQuery = 'Coupon Code'
export const getCouponCodeQueryKey = (queryParams: ParsedUrlQuery) => {
  const { gift_code } = queryParams
  return [CouponCodeQuery, { gift_code: String(gift_code) }] as const
}
export type CouponCodeQueryKey = ReturnType<typeof getCouponCodeQueryKey>

export const useQueryCouponCode = () => {
  const router = useRouter()
  const { gift_code } = router.query

  return useQuery({
    queryKey: getCouponCodeQueryKey(router.query),
    queryFn: fetchCouponCode,
    enabled: Boolean(gift_code),
    refetchOnWindowFocus: false,
  })
}

useQueryCouponCode.fetcher = fetchCouponCode
useQueryCouponCode.getKey = getCouponCodeQueryKey

/**
 * useFetchQueryCouponCode
 */
export const useFetchQueryCouponCode = () => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)

  const validateCouponCode = (gift_code: string) => {
    setIsLoading(true)
    try {
      return queryClient.fetchQuery({
        queryKey: getCouponCodeQueryKey({ gift_code }),
        queryFn: fetchCouponCode,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return { validateCouponCode, isLoading }
}
