import { logInfo } from '@/utils/logger'
import { getErrorMessage } from '@/utils/dataFetching/utils'

export function setupSW() {
  if (
    typeof window !== 'undefined' &&
    process.env.NODE_ENV === 'production' &&
    'serviceWorker' in navigator
  ) {
    // add this temp to completely remove all offlineCache options!
    if ('caches' in window) caches.delete('offlineCache')
    if (process.env.NEXT_PUBLIC_DISABLE_SW === 'true') {
      unregister()
    } else {
      register()
    }
  }
}

function unregister() {
  // do not wait until ServiceWorkerRegistration has an active ServiceWorker
  if (typeof navigator.serviceWorker !== 'undefined') {
    navigator.serviceWorker
      .getRegistration()
      .then((registration) => {
        if ('caches' in window) {
          caches
            .keys()
            .then((keyList) =>
              Promise.all(keyList.map((key) => caches.delete(key)))
            )
        }
        if (registration) {
          registration.unregister()
        }
      })
      .catch((error) => {
        // prevent uncaught exceptions
        logInfo('Error unregistering service worker:', {
          errorMessage: getErrorMessage(error),
        })
      })
  }
}

function register() {
  window.addEventListener('load', () => {
    if (typeof navigator.serviceWorker !== 'undefined') {
      navigator.serviceWorker
        .register('/service-worker.js')
        .catch((registrationError) => {
          logInfo('SW registration failed: ', {
            errorMessage: getErrorMessage(registrationError),
          })
        })
    }
  })
}
