import { headers } from '@/services/Authentication/mutations'
import { stringify } from '@/utils/queryString'
import { apiPost } from '@/utils/dataFetching'

export const fetchValidateResetToken = (token: string) => {
  return apiPost<boolean>({
    config: {
      url: 'auth/v2/validate-reset',
      data: stringify({ token }),
      headers,
    },
    options: {
      includeAuthHeader: false,
      forceLegacyGateway: true,
    },
    fetcherName: 'useMutationValidateResetToken',
  })
}
