import { apiGet } from '@/utils/dataFetching'
import { mfaPath } from '@/services/TwoFactorAuth/constants'
import { type TwoFactorChannelsResponse } from '@/services/Authentication/types'
import { type TwoFactorAuthPreferenceResponse } from '@/services/TwoFactorAuth/types'

export const fetchTwoFactorAuthPreference = () =>
  apiGet<TwoFactorAuthPreferenceResponse>({
    config: { url: `${mfaPath}/preference` },
    options: { forceLegacyGateway: true },
    fetcherName: 'fetchTwoFactorAuthPreference',
  })

export const fetchTwoFactorAuthChannels = () =>
  apiGet<TwoFactorChannelsResponse>({
    config: { url: `${mfaPath}/channels` },
    options: { forceLegacyGateway: true },
    fetcherName: 'fetchTwoFactorAuthChannels',
  })
