import { fontWeights } from '@/styles/typography/constants'
import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { BaseText } from '@/styles/typography/base.styled'

const { semibold } = fontWeights

const CalloutBase = styled(BaseText)`
  font-weight: ${semibold};
  margin: 0;
`
/**
 * @deprecated Use { Body } from '@shipt/design-system-typography' instead
 */
export const Callout1 = styled(CalloutBase)`
  line-height: 1.5rem;
  font-size: 1.0625rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 1.5rem;
      font-size: 1.1875rem;
    }
  `};
`
/**
 * @deprecated Use { Body } from '@shipt/design-system-typography' instead
 */
export const Callout2 = styled(CalloutBase)`
  line-height: 1.25rem;
  font-size: 0.9375rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 1.5rem;
      font-size: 1.0625rem;
    }
  `};
`
/**
 * @deprecated Use { Body } from '@shipt/design-system-typography' instead
 */
export const Callout3 = styled(CalloutBase)`
  line-height: 1rem;
  font-size: 0.8125rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 1.25rem;
      font-size: 0.9375rem;
    }
  `};
`
