import { useQuery } from '@tanstack/react-query'
import { fetchVisaBenefitDetails } from '@/services/VisaBenefits/fetcher'
import { useUserId } from '@/services/User/hooks'

const BenefitQuery = 'Benefits'

export const getEnrolledBenefitQueryKey = (userId: number) =>
  [BenefitQuery, userId] as const

export const useQueryEnrolledBenefit = (hasEnrolledVisa?: boolean) => {
  const userId = useUserId() ?? 0

  return useQuery({
    queryKey: getEnrolledBenefitQueryKey(userId),
    queryFn: fetchVisaBenefitDetails,
    enabled: Boolean(userId) && !hasEnrolledVisa,
  })
}

useQueryEnrolledBenefit.fetcher = fetchVisaBenefitDetails
useQueryEnrolledBenefit.getKey = getEnrolledBenefitQueryKey
