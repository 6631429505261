import { apiPost } from '@/utils/dataFetching'
import { type OauthTokenAPI } from '@/services/User/types'
import { getAuthClient } from '@/utils/authentication/client'
import { useMutation } from '@tanstack/react-query'
import {
  type FetchJWTPayload,
  type ResetTokenPayload,
  type ResetPasswordPayload,
} from '@/services/Authentication/types'
import { trackUserLoginError } from '@/analytics/users'
import { toError } from '@/utils/dataFetching/utils'
import { stringify } from '@/utils/queryString'
import {
  CHALLENGE_RESPONSE_HEADER,
  useLoginChallengeContext,
} from '@/context/LoginChallengeProvider'
import { fetchValidateResetToken } from '@/services/Authentication/fetchers'

export const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-User-Type': 'Customer',
}

export const useMutationFetchJWT = () => {
  const challengeValue = useLoginChallengeContext()

  return useMutation({
    mutationFn: (credentials: FetchJWTPayload) => {
      const { proofOfWorkCount, username, password } = credentials
      const cParam = proofOfWorkCount ? `&c=${proofOfWorkCount}` : ''
      const challengeHeader = challengeValue
        ? {
            [CHALLENGE_RESPONSE_HEADER]: `${challengeValue.rawString}${cParam}`,
          }
        : {}

      return apiPost<OauthTokenAPI>({
        config: {
          url: 'auth/v3/oauth/token',
          data: stringify({ username, password, grant_type: 'password' }),
          headers: {
            ...headers,
            ...challengeHeader,
          },
        },
        options: {
          includeAuthHeader: false,
          forceLegacyGateway: true,
          ignoredMessages: [
            /Username has already been taken/,
            'Invalid Username or Password',
            'Account is locked because of too many incorrect login attempts',
          ],
        },
        fetcherName: 'useMutationFetchJWT',
      })
    },
    onSuccess: async (data) => getAuthClient().persistence.setAuthData(data),
    onError: (error) => {
      trackUserLoginError(toError(error))
    },
  })
}
export const useMutationResetToken = () =>
  useMutation({
    mutationFn: (resetToken: ResetTokenPayload) =>
      apiPost<void>({
        config: {
          url: 'auth/v2/reset-token',
          data: stringify(resetToken),
          headers,
        },
        options: {
          includeAuthHeader: false,
          forceLegacyGateway: true,
        },
        fetcherName: 'useMutationResetToken',
      }),
  })

export const useMutationValidateResetToken = () =>
  useMutation({ mutationFn: fetchValidateResetToken })

export const useMutationResetPassword = () =>
  useMutation({
    mutationFn: (newCredentials: ResetPasswordPayload) =>
      apiPost<boolean>({
        config: {
          url: 'auth/v2/reset-password',
          data: stringify(newCredentials),
          headers,
        },
        options: {
          includeAuthHeader: false,
          ignoredMessages: ['Recent passwords cannot be reused'],
          forceLegacyGateway: true,
        },
        fetcherName: 'useMutationResetPassword',
      }),
  })

export const useMutationAuth0ChangePassword = () => {
  return useMutation({
    mutationFn: async (email: string) => {
      return apiPost<string>({
        config: {
          data: { email, connection: 'Shipt-Member-Database' },
        },
        options: {
          // See Auth0 Authentication API docs - https://auth0.com/docs/api/authentication#change-password
          overrideUrl: `${process.env.NEXT_PUBLIC_SHIPT_AUTH0_ISSUER_URL}/dbconnections/change_password`,
          includeAuthHeader: false,
        },
        fetcherName: 'useMutationAuth0ChangePassword',
      })
    },
  })
}
