import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { handleMarvAdviceCookieExpiration } from '@/utils/marv'
import { pageEvent } from '@/analytics/pageEvent'
import { useSession } from '@/utils/authentication/hooks'
import { useUserId } from '@/services/User/hooks'
import NProgress from 'nprogress'
import { staticColors } from '@/theme/staticColors'

export const useRouteChangeTracking = () => {
  const router = useRouter()
  const userId = useUserId()
  const { isSessionLoading, isSessionValid } = useSession()

  useEffect(() => {
    NProgress.configure({
      showSpinner: false,
      template: `<div class='bar' role='bar' style='background:${staticColors.lime};'><div class='peg' style='box-shadow:0 0 0.625rem ${staticColors.lime}, 0 0 0.3125rem ${staticColors.lime};'></div></div>`,
    })
  }, [])

  useEffect(() => {
    // if session is loading, do not track page event
    if (isSessionLoading) return
    // if session is valid and user data is not available yet, do not track page event
    if (isSessionValid && !userId) return
    // track page event for initial app page load
    pageEvent({ pathname: window.location.pathname, isInitialNavigation: true })
  }, [isSessionLoading, isSessionValid, userId])

  useEffect(() => {
    let mostRecentHref = window.location.href

    const startRouteChange = () => {
      NProgress.start()
      // this is invoked here to handle scenarios where a user's session is expired but the marv
      // cookie does not get cleared on SSR since we currently only handle session expirations
      // on tracking events which does not get called on SSR
      handleMarvAdviceCookieExpiration()
    }
    const completeRouteChange = () => {
      if (!(mostRecentHref === window.location.href)) {
        pageEvent({ pathname: window.location.pathname })
      }
      mostRecentHref = window.location.href
      NProgress.done()
    }
    router.events.on('routeChangeStart', startRouteChange)
    router.events.on('routeChangeComplete', completeRouteChange)
    router.events.on('routeChangeError', completeRouteChange)
    return () => {
      router.events.off('routeChangeStart', startRouteChange)
      router.events.off('routeChangeComplete', completeRouteChange)
      router.events.off('routeChangeError', completeRouteChange)
    }
  }, [router.events])
}
