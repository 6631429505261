import { apiGet } from '@/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type UpgradePlansQueryKey,
  type MembershipPlanQueryKey,
  type DowngradePlansQuery,
  type StudentEnrollmentsQuery,
  type SubscriptionHistoryQueryKey,
  type EBTEnrollmentsQuery,
} from '@/services/MembershipPlan/queries'
import {
  type EBTEnrollmentsResponse,
  type StudentEnrollmentsResponse,
  type SubscriptionResponse,
} from '@/services/MembershipPlan/types'
import { type FetchPackagesResponse } from '@/services/DeliveryPlans/types'

export const fetchMembershipPlan = (
  context: QueryFunctionContext<MembershipPlanQueryKey>
) => {
  const userId = context.queryKey[1].user_id

  return apiGet<Maybe<SubscriptionResponse>>({
    config: {
      url: `v2/customers/${userId}/subscriptions/active`,
    },
    context,
    fetcherName: 'fetchMembershipPlan',
  })
}

export const fetchSubscriptionHistory = (
  context: QueryFunctionContext<SubscriptionHistoryQueryKey>
) => {
  const userId = context.queryKey[1].user_id

  return apiGet<Maybe<SubscriptionResponse[]>>({
    config: {
      url: `v2/customers/${userId}/subscriptions`,
    },
    context,
    fetcherName: 'fetchSubscriptionHistory',
  })
}

export const fetchUpgradePlans = (
  context: QueryFunctionContext<UpgradePlansQueryKey>
) => {
  const params = context.queryKey[1]
  return apiGet<FetchPackagesResponse>({
    config: { url: 'prepaid-shops/v2/packages/upgrades', params },
    context,
    fetcherName: 'fetchUpgradePlans',
  })
}

export const fetchDowngradePlans = (
  context: QueryFunctionContext<DowngradePlansQuery>
) => {
  const params = context.queryKey[1]
  return apiGet<FetchPackagesResponse>({
    config: { url: 'prepaid-shops/v2/packages/downgrades', params },
    context,
    fetcherName: 'fetchDowngradePlans',
  })
}

export const fetchStudentEnrollments = (
  context: QueryFunctionContext<StudentEnrollmentsQuery>
) => {
  return apiGet<StudentEnrollmentsResponse>({
    config: { url: 'member-verification/v1/students/enrollments' },
    context,
    fetcherName: 'fetchStudentEnrollments',
  })
}

export const fetchEBTEnrollments = (
  context: QueryFunctionContext<EBTEnrollmentsQuery>
) => {
  return apiGet<EBTEnrollmentsResponse>({
    config: { url: 'member-verification/v1/ebt/enrollments' },
    context,
    fetcherName: 'fetchEBTEnrollments',
  })
}
