// this is to help prevent tokens from expiring in-flight
const preemptionInterval = 60_000

export const getIsTokenExpired = (expiresAt: number | undefined): boolean => {
  if (!expiresAt || isNaN(Number(expiresAt))) {
    return true
  }
  // token is expired if expiresAt (in ms) is earlier than a time 60 seconds in the future
  return expiresAt * 1_000 < Date.now() + preemptionInterval
}
