import { forwardRef, type ReactNode, type Ref } from 'react'
import styled from 'styled-components'
import { LoadingIndicator } from '@/elements/LoadingIndicator'
import { type StaticColor } from '@/theme/staticColors'

interface ButtonContainerProps {
  isLoading?: boolean
  stretch?: boolean
  isLink?: boolean
}

export interface ButtonProps extends ButtonContainerProps {
  className?: string
  hidden?: boolean
  children?: ReactNode
  disabled?: boolean
  loadingColor?: StaticColor
  type?: 'submit' | 'reset' | 'button'
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  tabIndex?: number
}

const ButtonContainer = styled.button<ButtonContainerProps>`
  display: ${({ isLink = false, isLoading }) =>
    (isLink && 'inline-block') || (isLoading && 'flex') || null};
  flex: ${({ isLoading }) => isLoading && 'row'};
  text-decoration: none;
  text-align: center;
  justify-content: ${({ isLoading }) => isLoading && 'center'};
  font-weight: 600;
  line-height: 1rem;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: all 0.1s ease-in;
  transition-property: color, background-color, border-color;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
`

export const BaseButton = forwardRef(
  (
    {
      children,
      className: _className = '',
      isLoading = false,
      loadingColor = 'white',
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <ButtonContainer
      ref={ref}
      className={_className}
      isLoading={isLoading}
      {...props}
    >
      {isLoading && (
        <LoadingIndicator isButton color={loadingColor} size="sm" />
      )}
      {children}
    </ButtonContainer>
  )
)
