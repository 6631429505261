import { useEffect } from 'react'
import { getCookie } from '@/utils/cookies'
import { useQueryUser } from '@/services/User/queries'
import { useMutationSendOptOutRequest } from '@/services/DataPrivacy/mutations'
import {
  optOutBrowserCookieIsActive,
  optOutBrowserSignalIsActive,
  optOutRestrictionsCookieIsActive,
  userIsDoNotShareSellTA,
} from '@/services/DataPrivacy/utils'
import { fetchHandleOptOutCookies } from '@/services/DataPrivacy/fetchers'
import { type RegulationCategoryStateAndReason } from '@/services/DataPrivacy/types'
import { SHIPT_RESTRICTION_BROWSER_COOKIE } from '@shared/constants/dataRights'
import { useIsGuestUser } from '@/services/User/hooks'
import { useQueryOptOutRestrictions } from '@/services/DataPrivacy/queries'

declare global {
  // Add globalPrivacyControl to the global Navigator type
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/globalPrivacyControl
  interface Navigator {
    globalPrivacyControl?: boolean
  }
  // Add the type for the global Window variable that Data Science uses to block
  // Google Tag Manager scripts from loading.
  interface Window {
    SHIPT_DO_NOT_SHARE_SELL_TA?: boolean
  }
}

export const useDataRights = () => {
  const { data: user } = useQueryUser({ notifyOnChangeProps: ['data'] })
  const { mutateAsync: sendOptOutRequest } = useMutationSendOptOutRequest()

  useEffect(() => {
    // If the user has opted out, set the global SHIPT_PRIVACY_BROWSER_COOKIE variable
    // to true so that the Google Tag Manager scripts can be blocked.
    if (userIsDoNotShareSellTA()) {
      window.SHIPT_DO_NOT_SHARE_SELL_TA = true
    }

    // Check the opt-out user preference from the response. If the preference is
    // set and the browser cookie isn't set, then we want to invoke the opt-out
    // API endpoint to set the cookies.
    if (
      user?.do_not_share_sell_ta && // Preference is true
      !optOutBrowserCookieIsActive() // Cookie is not set
    ) {
      // Make request to set cookies
      fetchHandleOptOutCookies()
    }

    // If the user has the do not sell, share, or targeted advertising browser signal
    // enabled, but their do_not_share_sell_ta user preference returned empty or false;
    // we need to submit to the identity workflow that they are using the browser signal.
    if (
      optOutBrowserSignalIsActive() && // Browser signal is active
      user && // User is authenticated
      !user.do_not_share_sell_ta // User's preference is falsy
    ) {
      // Make request to identity workflow indicating user opted out through browser signal.
      sendOptOutRequest({ request_source: 'web-signal' })
    }

    // If the user has the do not sell, share, or targeted advertising browser cookie,
    // but their do_not_share_sell_ta user preference returned empty or false;
    // we need to submit to the identity workflow that they submitted the opt out form.
    if (
      user && // User is authenticated
      !user.do_not_share_sell_ta // User's preference is falsy
    ) {
      if (optOutBrowserCookieIsActive()) {
        // Make request to identity workflow indicating user opted out through the web form.
        sendOptOutRequest({ request_source: 'web-form' })
      } else if (optOutRestrictionsCookieIsActive()) {
        // Make request to identity workflow indicating user opted out through the web restriction with the restrictionCookieValues.
        const restrictionCookieValues: RegulationCategoryStateAndReason =
          getCookie(SHIPT_RESTRICTION_BROWSER_COOKIE)
        sendOptOutRequest({
          request_source: 'web-restriction',
          ...restrictionCookieValues,
        })
      }
    }
  }, [sendOptOutRequest, user])
}

/**
 * Determines if a data rights related query should be enabled.
 * @param {boolean} enabled - A boolean flag indicating if the query this hook wraps around should be enabled or not.
 * @returns {boolean} - A boolean value indicating whether the query should be enabled based on the provided `enabled` parameter and additional conditions.
 */
export const useIsDataRightsRelatedQueryEnabled = (enabled: boolean) => {
  const isGuestUser = useIsGuestUser()
  const { isFetched } = useQueryOptOutRestrictions()

  if (!enabled) {
    return false
  }
  if (isGuestUser && !userIsDoNotShareSellTA()) {
    return isFetched
  }
  return true
}
