import {
  type VisaBenefitTier,
  type VisaBenefitTiers,
  VisaBenefitType,
} from '@/services/VisaBenefits/types'

const getDurationText = (durationMonths: number) => {
  if (durationMonths === 12) return '1 year'
  if (durationMonths % 12 === 0) return `${durationMonths / 12} years`
  if (durationMonths > 1) return `${durationMonths} months`
  return `${durationMonths} month`
}
export const VISA_BENEFIT_TIERS: VisaBenefitTiers = {
  [VisaBenefitType.Traditional]: {
    id: VisaBenefitType.Traditional,
    name: 'Traditional',
    durationMonths: 1,
    durationText: getDurationText(1),
    discountedDurationMonths: 3,
    discountedDurationText: getDurationText(3),
    totalDurationMonths: 4,
    totalDurationText: getDurationText(4),
    existingDurationMonths: 3,
    existingDurationText: getDurationText(3),
  },
  [VisaBenefitType.Signature]: {
    id: VisaBenefitType.Signature,
    name: 'Signature',
    durationMonths: 3,
    durationText: getDurationText(3),
    discountedDurationMonths: 9,
    discountedDurationText: getDurationText(9),
    totalDurationMonths: 12,
    totalDurationText: getDurationText(12),
    existingDurationMonths: 6,
    existingDurationText: getDurationText(6),
  },
  [VisaBenefitType.Infinite]: {
    id: VisaBenefitType.Infinite,
    name: 'Infinite',
    durationMonths: 36,
    durationText: getDurationText(36),
    totalDurationMonths: 36,
    totalDurationText: getDurationText(36),
    existingDurationMonths: 36,
    existingDurationText: getDurationText(36),
    isMax: true,
  },
}

export const getBenefitDetails = (
  type?: Maybe<VisaBenefitType>
): Partial<VisaBenefitTier> => (type ? VISA_BENEFIT_TIERS[type] || {} : {})

export const VISA_TERMS_URL = 'https://www.shipt.com/offer-terms-for-visa'
export const VISA_FAQ_URL = 'https://help.shipt.com/offer-faqs-for-visa'
