import Cookies, { type CookieAttributes } from 'js-cookie'
import { isOnServer } from '@shared/constants/util'

export const setCookie = <T extends object>(
  key: string,
  value: T | string,
  options?: CookieAttributes
) => {
  if (isOnServer()) return
  try {
    Cookies.set(key, value, options)
  } catch (err) {
    // no op
  }
}

export const getCookie = (key: string) => {
  if (isOnServer()) return
  const data = Cookies.get(key)
  if (data) {
    try {
      return JSON.parse(data)
    } catch (err) {
      // parsing string failed
      return data
    }
  }
  return null
}

export const getCookieRaw = (key: string) => Cookies.get(key)

export const removeCookie = (key: string, options?: CookieAttributes) => {
  if (isOnServer()) return
  try {
    return Cookies.remove(key, options)
  } catch (err) {
    // no op
  }
}
