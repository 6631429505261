import {
  AnalyticsEvent,
  type ElementClicked,
  type ElementViewed,
} from '@shipt/analytics-member-web'
import { track } from '@/analytics/trackingQueue'
import { urlPathnameToLocationName } from '@/analytics/utils'
import { type ShiptEventOptions } from '@shipt/analytics-core'

export const trackElementViewed = (
  eventProperties: Omit<ElementViewed['properties'], 'location'>
) =>
  track({
    eventName: AnalyticsEvent.ElementViewed,
    properties: { ...eventProperties, location: urlPathnameToLocationName() },
  })

export const trackElementClicked = (
  eventProperties: Omit<ElementClicked['properties'], 'location'>,
  eventOptions?: Partial<ShiptEventOptions>
) =>
  track({
    eventName: AnalyticsEvent.ElementClicked,
    properties: { ...eventProperties, location: urlPathnameToLocationName() },
    eventOptions,
  })
