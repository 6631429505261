export enum DISPLAY_FORMAT {
  HORIZONTAL = 'horizontal_list',
  VERTICAL = 'vertical_list',
  GRID = 'grid',
  DETAIL = 'product_detail_page',
  SHELF = 'shelf',
  ANIMATION = 'animation',
  IMAGE = 'image',
  ORIGINAL_PRODUCT = 'original_product',
}
