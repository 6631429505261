import { appEnv, appVersion } from '@shared/constants/build'
import { type GetServerSidePropsContext } from 'next'
import { type AxiosRequestConfig } from 'axios'

type RequestHeaderParams = {
  isGQL: boolean
  ssrContext?: GetServerSidePropsContext
}

export const getCommonRequestHeaders = ({
  isGQL,
  ssrContext,
}: RequestHeaderParams) => {
  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': 'application/json',
    ...(isGQL && {
      'apollographql-client-name': `web-shipt-${appEnv}`,
      'apollographql-client-version': appVersion,
    }),
  }
  if (ssrContext && process.env.SHIPT_SEGWAY_INTERNAL_VALUE) {
    // pass internal header and value for internal api requests
    headers['shipt-segway-internal'] = process.env.SHIPT_SEGWAY_INTERNAL_VALUE
  }
  // pass along fastly-ray-id for request tracing in logs
  if (ssrContext?.req.headers?.['fastly-ray-id']) {
    headers['fastly-ray-id'] = ssrContext.req.headers['fastly-ray-id']
  }
  // pass along user-agent to help downstream services debug
  if (ssrContext) {
    headers['X-Browser-User-Agent'] =
      ssrContext.req.headers?.['user-agent'] ?? ''
    headers['X-Shipt-Segway-SSR'] = 'true'
  }
  return headers
}
