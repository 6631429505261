export const LEGAL_LINKS = {
  GIFT_CARDS: 'https://www.shipt.com/legal/giftcards',
  TERMS_OF_SERVICE: 'https://www.shipt.com/legal/terms-of-service',
  TEXT_MESSAGE_TERMS: 'https://www.shipt.com/legal/text-message-terms',
  PRIVACY_POLICY: 'https://www.shipt.com/legal/privacy-policy',
  CA_PRIVACY_RIGHTS: 'https://www.shipt.com/legal/privacy-policy#california',
  WA_PRIVACY_RIGHTS: 'https://www.shipt.com/legal/privacy-policy#washington',
  INTERNET_BASED_ADS:
    'https://www.shipt.com/legal/privacy-policy#online-ad-choices',
}
