import { type usePopover } from '@shipt/design-system-popovers'

// preserve type of object keys
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const getObjectKeys = Object.keys as <
  T extends Record<PropertyKey, unknown>
>(
  obj: T
) => (keyof T)[]

// preserve type of object keys
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const getObjectEntries = Object.entries as <
  T extends Record<PropertyKey, unknown>
>(
  obj: T
) => [keyof T, T[keyof T]][]

export const getObjectFromEntries = <K extends PropertyKey, V>(
  entries: Iterable<readonly [K, V]>
) => {
  // preserve the specific key and value types provided by the generic parameters
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.fromEntries(entries) as Record<K, V>
}

export type IsEmptyObject<Obj extends Record<PropertyKey, unknown>> = [
  keyof Obj
] extends [never]
  ? true
  : false

// update properties to be optional
export type Optional<
  Obj extends object,
  Key extends keyof Obj = keyof Obj
> = Omit<Obj, Key> & Partial<Pick<Obj, Key>>

export type NullableObject<Obj> = {
  [K in keyof Obj]: Obj[K] | null | undefined
}

// update every property to be nullable
export type NullableDeepObject<Obj> = {
  [Key in keyof Obj]: NullableDeepObject<Maybe<Obj[Key]>>
}

export type PopoverReferenceProps = ReturnType<
  ReturnType<typeof usePopover>['getReferenceProps']
>

export type NonEmptyArray<T> = [T, ...T[]]

// ability to use types with "Discriminated Unions" in Omit statements
export type MappedOmit<T, K extends keyof T> = {
  [P in keyof T as P extends K ? never : P]: T[P]
}
