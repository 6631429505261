// Cookie names
export const SHIPT_PRIVACY_BROWSER_COOKIE = 'ShiptPrivacy'
export const SHIPT_PRIVACY_SERVER_COOKIE = 'ShiptPrivacyPreference'
export const SHIPT_RESTRICTION_BROWSER_COOKIE = 'ShiptOptOutRestriction'
export const SHIPT_RESTRICTION_SERVER_COOKIE = 'ShiptOptOutRestrictionServer'

// Cookie value
export const SHIPT_PRIVACY_COOKIE_VALUE = 'Y'

// Browser signal header
export const OPT_OUT_BROWSER_SIGNAL_HEADER_KEY = 'sec-gpc'
export const OPT_OUT_BROWSER_SIGNAL_HEADER_VALUE = '1'
