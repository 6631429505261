import { type SubscriptionResponse } from '@/services/MembershipPlan/types'
import { type SelectedPaymentMethod } from '@/state/CheckoutPreferences/types'
import { type Dispatch, type SetStateAction } from 'react'

export type DeliveryPlansPackage = {
  id?: Maybe<number | string>
  total_cost_cents?: Maybe<number>
  total_base_cents?: Maybe<number>
  text?: Maybe<DeliveryPlansPackageText>
  unlimited_interval?: Maybe<UnlimitedInterval>
  renewal_cost_cents?: Maybe<number>
  trial_period_days?: Maybe<number>
  plan_type?: Maybe<string>
}

type DeliveryPlansPackageText = {
  purchase_notice?: Maybe<string>
  upgrade_notice?: Maybe<string>
  downgrade_notice?: Maybe<string>
  line_item_title?: Maybe<string>
  auto_renewal?: Maybe<AutoRenewalText>
  promo_expiration?: Maybe<string>
  next_full_charge_at?: Maybe<string>
}

export type AutoRenewalText = {
  notice?: Maybe<AutoRenewalTextSegment[]>
  check_box?: Maybe<AutoRenewalTextSegment[]>
  notes?: Maybe<AutoRenewalTextSegment[][]>
}

export type AutoRenewalTextSegment = {
  copy?: Maybe<string>
  link_url?: Maybe<string>
  style?: Maybe<string>
}

export enum UnlimitedInterval {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export enum PackagePlanType {
  STUDENT = 'student',
  EBT = 'ebt',
  STANDARD = 'standard',
  DISCOUNT = 'discount',
  EARLY_RENEWAL = 'early_renewal',
}

export type FetchPackagesPayload = {
  packages?: Maybe<DeliveryPlansPackage[]>
  single_delivery_fee_cents?: Maybe<number>
}

export type FetchPackagesResponse = {
  data?: Maybe<FetchPackagesPayload>
}

export type UnlimitedPurchaseResponse = {
  data: {
    subscription?: Maybe<SubscriptionResponse>
  }
}

export type SignupPlansPayload = {
  metro_id?: number | null
  zip_code: string
  param: string
  email?: string
  referring_url: string
}

export type PlanMutationPayload = {
  package_id: number | string
  credit_card_id: number
}

export type PurchasePlanHook = {
  setButtonLoading: Dispatch<SetStateAction<boolean>>
  paymentMethodPref: SelectedPaymentMethod | null
  planId: number | string
  setErrorMessage: Dispatch<SetStateAction<string>>
  setIsAddingPayment?: Dispatch<SetStateAction<boolean>>
  onSuccess: () => void
}
