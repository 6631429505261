export enum SubscriptionId {
  GROCERY_YEARLY = 'GROCERY_YEARLY',
  GROCERY_YEARLY_WITH_TRIAL = 'GROCERY_YEARLY_WITH_TRIAL',
  GROCERY_MONTHLY = 'GROCERY_MONTHLY',
  GROCERY_MONTHLY_10_99 = 'GROCERY_MONTHLY_10_99',
  GROCERY_MONTHLY_8_99 = 'GROCERY_MONTHLY_8_99',
  GROCERY_MONTHLY_7_99 = 'GROCERY_MONTHLY_7_99',
  GROCERY_SIX_MONTHS = 'GROCERY_SIX_MONTHS',
  VISA_MONTHLY = 'VISA_MONTHLY',
  STUDENT_MONTHLY = 'STUDENT_MONTHLY_4_99',
  SNAP_EBT_MONTHLY = 'EBT_MONTHLY_4_99',
}

export enum MembershipInterval {
  year = 'year',
  month = 'month',
}

export enum MembershipStatus {
  trialing = 'trialing',
  active = 'active',
}

export enum MembershipPlanType {
  MARKETPLACE = 'Marketplace.0',
  PLATFORM = 'Retailer.27',
  CIRCLE_360 = 'Trident.27',
}

export type PlanType =
  | 'Annual'
  | 'Monthly'
  | 'Membership'
  | `${number} Year`
  | `${number} Month`

export type PlanName = PlanType | 'Student' | 'SNAP EBT'

export type Subscription = {
  id: SubscriptionId | ''
  name: string
  trialDays: Maybe<number>
  metadata: unknown
  price: number
  startDate: string
  currentPeriodStartDate: string
  renewalDate: string
  trialStart: string
  trialEnd: string
  duration: string
  cancelAtPeriodEnd: boolean
  interval: MembershipInterval | ''
  intervalCount: number
  refundable: boolean
  status: MembershipStatus | ''
  schedule: SubscriptionSchedule
  type: MembershipPlanType | ''
}

export type SubscriptionPlan = {
  id?: Maybe<SubscriptionId>
  name?: Maybe<string>
  trial_period_days?: Maybe<number>
  amount?: Maybe<number>
  interval?: Maybe<MembershipInterval>
  interval_count?: Maybe<number>
  currency?: Maybe<string>
  duration?: Maybe<string>
  metadata?: Maybe<unknown>
}

type SubscriptionDiscount = {
  coupon_id?: Maybe<string>
}

export type SubscriptionResponse = {
  status?: Maybe<MembershipStatus>
  plan?: Maybe<SubscriptionPlan>
  current_period_end?: Maybe<number>
  current_period_start?: Maybe<number>
  cancel_at_period_end?: Maybe<boolean>
  schedule?: Maybe<SubscriptionSchedule>
  start?: Maybe<number>
  trial_end?: Maybe<number>
  trial_start?: Maybe<number>
  refundable?: Maybe<boolean>
  type?: Maybe<MembershipPlanType>
  discount?: Maybe<SubscriptionDiscount>
}

export type SubscriptionPhasePlan = {
  plan?: Maybe<SubscriptionId>
  quantity?: Maybe<number>
}

export type SubscriptionPhase = {
  plans?: Maybe<SubscriptionPhasePlan[]>
  start_date?: Maybe<number>
  end_date?: Maybe<number>
  trial_end?: Maybe<number>
  coupon?: Maybe<string>
  // the subscriptions service does not currently return
  // amount but may at some point in the future
  amount?: Maybe<number>
}

export type SubscriptionSchedule = {
  external_id?: Maybe<string>
  canceled_at?: Maybe<number>
  completed_at?: Maybe<number>
  created?: Maybe<number>
  current_phase?: Maybe<{
    start_date?: Maybe<number>
    end_date?: Maybe<number>
  }>
  end_behavior?: Maybe<string>
  phases?: Maybe<SubscriptionPhase[]>
  status?: Maybe<string>
}

export type SignupPlan = {
  credit_amount?: Maybe<number>
  duration?: Maybe<string>
  id?: Maybe<number>
  interval?: Maybe<MembershipInterval>
  membership_discount?: Maybe<number>
  metadata?: Maybe<Maybe<string>[]>
  name?: Maybe<string>
  price?: Maybe<number>
  signup_promotion_id?: Maybe<number>
  stripe_coupon_id?: Maybe<string>
  stripe_plan_id?: Maybe<string>
  trial_days?: Maybe<number>
}

export type SignupPlansResponse = {
  signup_plans?: Maybe<SignupPlan[]>
}

export enum CancellationSurveyReason {
  COST = 'Cost of service is too high',
  ORDER_EXPERIENCE = 'Poor order experience',
  DONT_USE = "I don't use the service enough",
  OTHER = 'Other',
}

export type StudentVerificationCodeResponse = {
  is_valid: boolean
  reverify_before?: string
  reason?: string
}

export type StudentEnrollmentsResponse = {
  user_id?: Maybe<string>
  email?: Maybe<string>
  status?: Maybe<string>
  email_validated?: Maybe<boolean>
  email_verified?: Maybe<boolean>
  matched_school?: Maybe<string>
  last_verified_at?: Maybe<string>
  expires_at?: Maybe<string>
  reverify_before?: Maybe<string>
}

export type EBTEnrollmentsResponse = {
  user_id?: string
  card_id?: string
  card_validated?: boolean
  subscription_status?: string
  last_verified_at?: string
  reverify_before?: string
}

export type CreateEBTEnrollmentsResponse = EBTEnrollmentsResponse & {
  ineligible_reason?: string
}

export type CreateEBTEnrollmentsPayload = {
  ebtToken?: string
  ebtCardId?: string
}
