import { createContext, useContext, type ReactNode } from 'react'
import { type ClientSafeSsrContext } from '@/utils/setPageProps'

type Props = {
  ssrContext: ClientSafeSsrContext | undefined
  children: ReactNode
}

const ServerSsrContext = createContext<Props['ssrContext']>(undefined)

export const ServerSsrContextProvider = ({ ssrContext, children }: Props) => (
  <ServerSsrContext.Provider value={ssrContext}>
    {children}
  </ServerSsrContext.Provider>
)

export const useServerSsrContext = () => useContext(ServerSsrContext)
