import {
  AnalyticsEvent,
  type PromoCardClicked,
} from '@shipt/analytics-member-web'
import { DISPLAY_FORMAT } from '@/constants/analytics'
import { urlPathnameToLocationName } from '@/analytics/utils'
import { type Promotion } from '@/services/Campaigns/types'
import { type Coupon } from '@/services/Coupons/types'
import { track } from '@/analytics/trackingQueue'
import { PromotionScopeType } from '@/analytics/promotions'
import { type ShiptEventOptions } from '@shipt/analytics-core'

export type PromoCardEvent = Omit<
  PromoCardClicked['properties'],
  'display_format'
> & { display_format?: DISPLAY_FORMAT }

export interface PromoCardProps {
  displayFormat?: DISPLAY_FORMAT
  gridIndex?: number
  listIndex?: number
  shelfIndex?: number
  subLocation?: string
  nextLocation?: string
}

interface FeaturedPromoCardProps extends PromoCardProps {
  promotion: Promotion
}

export const getFeaturedPromoCardProperties = ({
  promotion,
  displayFormat,
  gridIndex,
  listIndex,
  subLocation,
  shelfIndex,
  nextLocation,
}: FeaturedPromoCardProps): PromoCardEvent => {
  return {
    location: urlPathnameToLocationName(),
    ...(subLocation && { display_sublocation: subLocation }),
    ...(displayFormat && { display_format: displayFormat }),
    grid_index: gridIndex,
    list_index: listIndex,
    shelf_index: shelfIndex,
    creative: promotion?.promotion_params?.images?.[0]?.image_url,
    promotion_type_id: Number(
      promotion.promotion_params?.feature_promotion_group_id || -1
    ),
    promotion_type_name:
      promotion?.type || PromotionScopeType.featured_promotion,
    promotion_id: String(promotion?.id) || '',
    promotion_name: promotion.display_name ?? '',
    ...(promotion.promotion_params?.category_id && {
      promotion_category_id:
        String(promotion.promotion_params?.category_id) || '',
    }),
    ...(nextLocation && { next_location: nextLocation }),
  }
}

export interface CouponPromoCardProps extends PromoCardProps {
  coupon: Coupon
  couponClipped?: boolean
  actionType?: 'clip' | 'shop' | 'coupon_terms' | 'view'
}

export const getCouponPromoCardProperties = ({
  coupon,
  displayFormat,
  gridIndex,
  listIndex,
  subLocation,
  couponClipped,
  actionType,
  shelfIndex,
}: CouponPromoCardProps): PromoCardEvent => {
  return {
    location: urlPathnameToLocationName(),
    ...(actionType && { action_type: actionType }),
    ...(subLocation && { display_sublocation: subLocation }),
    ...(displayFormat && { display_format: displayFormat }),
    grid_index: gridIndex,
    list_index: listIndex,
    shelf_index: shelfIndex,
    promotion_type_name: PromotionScopeType.coupon,
    coupon_id: coupon.couponId,
    coupon_name: coupon.summary || '',
    coupon_clipped: Boolean(couponClipped),
  }
}

export const trackPromoCardViewed = (eventProperties: PromoCardEvent) =>
  track({
    eventName: AnalyticsEvent.PromoCardViewed,
    properties: getPromoCardProperties(eventProperties),
  })

export const trackPromoCardClicked = (
  eventProperties: PromoCardEvent,
  eventOptions?: Partial<ShiptEventOptions>
) =>
  track({
    eventName: AnalyticsEvent.PromoCardClicked,
    properties: getPromoCardProperties(eventProperties),
    eventOptions,
  })

const getPromoCardProperties = (
  eventProperties: PromoCardEvent
): PromoCardClicked['properties'] => {
  const { list_index, display_format } = eventProperties ?? {}
  return {
    display_format: DISPLAY_FORMAT.HORIZONTAL,
    ...(!display_format && !list_index && { list_index: 0 }),
    ...eventProperties,
  }
}
