import { type QueryClient } from '@tanstack/react-query'
import { type Address, type AddressApiError } from '@/services/Addresses/types'
import { addressQueryOptions } from '@/services/Addresses/queries'
import { isErrorResponseType } from '@/utils/dataFetching/utils'

export const getAddresses = (queryClient: QueryClient) =>
  queryClient.getQueryData(addressQueryOptions.queryKey)

export const getAddress = (addressId: number, queryClient: QueryClient) =>
  getAddresses(queryClient)?.find((address) => address.id === addressId)

export const getAddressError = (
  ...errors: unknown[]
): AddressApiError | null => {
  return (
    errors.find(isErrorResponseType<AddressApiError>)?.response?.data?.error ??
    null
  )
}

type GetIsDoorDropoffProps = {
  address: Address
  hasRestrictedItems: boolean
  isPickup: boolean
}
export function getIsDoorDropoff({
  address,
  hasRestrictedItems,
  isPickup,
}: GetIsDoorDropoffProps): boolean {
  // An order cannot be dropped off if it is a pickup order or it contains
  // restricted items because they require a signature.
  return (address?.door_dropoff ?? true) && !hasRestrictedItems && !isPickup
}
