import { isOnServer } from '@shared/constants/util'
import {
  USER_FETCH_METHOD_KEY,
  UserFetchMethod,
} from '@shared/constants/dataFetching'
import { getCookie } from '@/utils/cookies'

export const getIsUserGraphqlQueryEnabled = () => {
  if (isOnServer()) {
    return process.env.USER_FETCH_METHOD === UserFetchMethod.GRAPHQL
  }

  return getCookie(USER_FETCH_METHOD_KEY) === UserFetchMethod.GRAPHQL
}
