import { type CookieOptions } from 'express'

const maxAge = 365 * 24 * 60 * 60 * 1000 // 1 year, in milliseconds
export const cookieOptions: CookieOptions = {
  maxAge,
  httpOnly: true,
  secure: true,
  domain: process.env.NEXT_PUBLIC_WHITE_LABEL_ROOT_DOMAIN,
  sameSite: 'lax',
  path: '/',
  ...(process.env.NEXT_PUBLIC_ENV === 'local' && {
    secure: false,
    domain: undefined,
  }),
}

export const secureClientOptions: CookieOptions = {
  ...cookieOptions,
  httpOnly: false,
}

export const insecureCookieOptions: CookieOptions = {
  maxAge,
  domain: process.env.NEXT_PUBLIC_WHITE_LABEL_ROOT_DOMAIN,
  sameSite: 'lax',
  path: '/',
  httpOnly: false, // let javascript see the cookie
  ...(process.env.NEXT_PUBLIC_ENV === 'local' && {
    domain: undefined,
  }),
}

export const secureClientOptionsNoDomain: CookieOptions = {
  ...secureClientOptions,
  domain: undefined,
}

export const cookieName = process.env.NEXT_PUBLIC_AUTH_COOKIE_KEY!

export const newAuthOverrideCookieName = 'sa_bf'
export const newAuthPublicCookieName = 'newAuth_rollout'
export const hasNewAuthSessionCookieName = 'newAuth_hasSession'

export const loginCompletedTriggerCookieName = 'na_lc'
