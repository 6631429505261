// aristotle experiments - editable in optimizely
// when we add or remove values from this file, we'll want to update Dynatrace to add/remove the experiment to session tracking
export enum ExperimentType {
  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/address_entry_phase_1b/rules/development/edit/address_entry_phase_1b
  ADDRESS_ENTRY_PHASE_1B = 'address_entry_phase_1b',

  // https://app.optimizely.com/v2/projects/19950886887/experiments/28840110325/variations
  ADD_PAST_SHOPPERS_AS_PREFERRED = 'add_past_shoppers_as_preferred',

  // https://app.optimizely.com/v2/projects/19950886887/experiments/22180001975
  AUTO_RENEWAL_LANGUAGE_WEB = 'auto_renewal_language_web',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/ShFV7J65PrVtbzDrjqArSL/variations
  BACKUP_REDESIGN = 'backup_redesign_experiment',

  // https://app.optimizely.com/v2/projects/19950886887/experiments/24544690377/variations
  DIETARY_NUTRITION_FILTERS = 'dietary-nutrition-filters',

  //https://app.optimizely.com/v2/projects/19950886887/flags/manage/global_stores_drawer_web/rules/development/edit/global_stores_drawer_web
  GLOBAL_STORES_DRAWER_WEB = 'global_stores_drawer_web',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/junk_fees_web/rules/development
  JUNK_FEES_WEB = 'junk_fees_web',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/manual_reschedules/rules/development
  MANUAL_RESCHEDULES = 'manual_reschedules',

  // https://app.optimizely.com/v2/projects/19950886887/experiments/27446100065/variations
  // originally (v1): 'web_membership_placement_at_checkout' -> https://app.optimizely.com/v2/projects/19950886887/experiments/25268221042/variations
  MEMBERSHIP_PLACEMENT_AT_CHECKOUT = 'checkout_membership_checkbox_v2',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/new_stores_banner_on_ghp
  NEW_STORES_BANNER = 'new_stores_banner',

  // https://app.optimizely.com/v2/projects/19950886887/experiments/29267450185/variations
  NO_MARKUP_TEST = 'no_markup_test',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/no_service_fees/rules/development
  NO_SERVICE_FEES = 'no_service_fees',

  // https://app.optimizely.com/v2/projects/19950886887/flags/manage/progressive_reveal/rules/development
  PROGRESSIVE_REVEAL = 'progressive_reveal',

  // https://app.optimizely.com/v2/projects/19950886887/experiments/27747410503/variations
  REENGAGEMENT_ON_GHP = 'reengagement_web_and_app',
}
