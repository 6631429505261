import { type StoreParams } from '@/utils/dataFetching/storeParams'
import { createContext, useContext, type ReactNode } from 'react'

export const defaultStoreContextValue = {
  zip: '',
  user_id: 0,
  store_id: null,
  metro_id: null,
  store_location_id: null,
}

const StoreContext = createContext<StoreParams>(defaultStoreContextValue)

type Props = { children: ReactNode; storeParams: StoreParams }

export const GlobalStoreContextProvider = ({
  children,
  storeParams,
}: Props) => {
  return (
    <StoreContext.Provider value={storeParams}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStoreContext = () => useContext(StoreContext)
