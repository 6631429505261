import { useState } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { type GetServerSidePropsContext } from 'next'
import { isOnServer } from '@shared/constants/util'
import { minutesToMilliseconds } from 'date-fns'

declare module '@tanstack/react-query' {
  interface Register {
    queryMeta: {
      ssrContext?: GetServerSidePropsContext
      observerThreshold?: number
    }
    mutationMeta: { ssrContext: GetServerSidePropsContext | undefined }
  }
}

// singleton instance of the queryClient used on member devices
// most of the time this will be provided by the <QueryClientProvider> in _app.tsx
// but its here for convenience when used by getQueryClient
let _queryClient: QueryClient | undefined

const createQueryClient = (ssrContext?: GetServerSidePropsContext) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        meta: {
          // by setting ssrContext here, we don't need to pass it around
          // in every method that might need access to the context
          ssrContext,
        },
        // we want API calls to adhere to a retry "opt-in" policy by default
        // to prevent unnecessary API calls
        retry: false,
        staleTime: minutesToMilliseconds(30),
      },
      mutations: {
        meta: {
          ssrContext,
        },
      },
    },
  })

export const getQueryClient = (ssrContext?: GetServerSidePropsContext) => {
  if (isOnServer()) return createQueryClient(ssrContext)
  if (!_queryClient) {
    _queryClient = createQueryClient()
  }
  return _queryClient
}

// https://react-query.tanstack.com/guides/ssr#using-hydration
// Create a new QueryClient instance inside of your app, and on an instance ref (or in React state).
// This ensures that data is not shared between different users and requests,
// while still only creating the QueryClient once per component lifecycle.
export const useReactQuery = () => {
  const [queryClient] = useState(() => getQueryClient())
  return queryClient
}
