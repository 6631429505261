import { useEffect, useRef } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { urlPathnameToLocationName } from '@/analytics/utils'
import { logInfo } from '@/utils/logger'

export const OBSERVERS_THRESHOLD = 100
export const CHECK_OBSERVER_INTERVAL = 30000 // 30 seconds

export const useLogExcessiveQueryObservers = () => {
  const queryClient = useQueryClient()
  const loggedQueries = useRef(new Set<string>())

  useEffect(() => {
    const loggedQueriesCurrent = loggedQueries.current

    const checkObserverCount = () => {
      const location = urlPathnameToLocationName()
      const allQueries = queryClient.getQueryCache().getAll()

      allQueries.forEach((query) => {
        const queryKey = query.queryKey[0]
        const loggedKey = `${queryKey}-${location}`
        if (loggedQueriesCurrent.has(loggedKey)) return

        const observersCount = query.getObserversCount()

        const threshold = query.meta?.observerThreshold ?? OBSERVERS_THRESHOLD
        if (observersCount > threshold) {
          logInfo('Query with High Observers Count', {
            location,
            queryKey,
            observersCount,
          })
          loggedQueriesCurrent.add(loggedKey)
        }
      })
    }
    const intervalId = setInterval(checkObserverCount, CHECK_OBSERVER_INTERVAL)

    return () => {
      clearInterval(intervalId)
      loggedQueriesCurrent.clear()
    }
  }, [queryClient])
}
