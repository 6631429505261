import { queryOptions, useQuery } from '@tanstack/react-query'
import { useStoreParams } from '@/utils/dataFetching/storeParams'
import { useIsUserExempt, useUserId } from '@/services/User/hooks'
import {
  MembershipInterval,
  MembershipPlanType,
} from '@/services/MembershipPlan/types'
import { toMembershipPlan } from '@/services/MembershipPlan/utils'
import {
  fetchDowngradePlans,
  fetchEBTEnrollments,
  fetchMembershipPlan,
  fetchStudentEnrollments,
  fetchSubscriptionHistory,
  fetchUpgradePlans,
} from '@/services/MembershipPlan/fetchers'
import { type QueryOptions } from '@/utils/dataFetching/types'

const UpgradePlansQueryKey = 'Upgrade plans'
export const DowngradePlansQueryKey = 'Downgrade Plans'

export const getMembershipPlanQueryKey = (user_id: number) => {
  return ['Membership Plan', { user_id }] as const
}

export type MembershipPlanQueryKey = ReturnType<
  typeof getMembershipPlanQueryKey
>

export const membershipPlanQueryOptions = (userId: number) =>
  queryOptions({
    queryKey: getMembershipPlanQueryKey(userId),
    queryFn: fetchMembershipPlan,
  })

export const useQueryMembershipPlan = (options?: QueryOptions) => {
  const userId = useUserId()

  return useQuery({
    ...membershipPlanQueryOptions(userId ?? 0),
    enabled: Boolean(userId),
    ...options,
  })
}
useQueryMembershipPlan.getKey = getMembershipPlanQueryKey
useQueryMembershipPlan.fetcher = fetchMembershipPlan

export const getSubscriptionHistoryQueryKey = (user_id: number) => {
  return ['Subscription History', { user_id }] as const
}

export type SubscriptionHistoryQueryKey = ReturnType<
  typeof getSubscriptionHistoryQueryKey
>

export const useQuerySubscriptionHistory = () => {
  const userId = useUserId()
  const isUserExempt = useIsUserExempt()

  return useQuery({
    queryKey: getSubscriptionHistoryQueryKey(userId ?? 0),
    queryFn: fetchSubscriptionHistory,
    enabled: Boolean(!isUserExempt && userId),
  })
}

export const getUpgradePlansQueryKey = (metro_id: number) =>
  [UpgradePlansQueryKey, { metro_id }] as const

export type UpgradePlansQueryKey = ReturnType<typeof getUpgradePlansQueryKey>

export const useQueryUpgradePlans = (isEnabled: boolean) => {
  const { data: subscriptionResponse } = useQueryMembershipPlan()
  const membership = toMembershipPlan(subscriptionResponse)
  const storeParams = useStoreParams()
  const userId = useUserId()
  const { metro_id } = storeParams ?? {}

  return useQuery({
    queryKey: getUpgradePlansQueryKey(metro_id ?? 0),
    queryFn: fetchUpgradePlans,
    select: (data) => data.data?.packages ?? [],
    enabled:
      Boolean(
        userId &&
          membership?.interval === MembershipInterval.month &&
          membership.type !== MembershipPlanType.CIRCLE_360
      ) && isEnabled,
  })
}

export const getDowngradePlansQueryKey = (metro_id: number) =>
  [DowngradePlansQueryKey, { metro_id }] as const

export type DowngradePlansQuery = ReturnType<typeof getDowngradePlansQueryKey>

export const useQueryDowngradePlans = () => {
  const storeParams = useStoreParams()
  const { metro_id } = storeParams ?? {}
  const userId = useUserId()
  const isExempt = useIsUserExempt()
  const { data: subscriptionResponse } = useQueryMembershipPlan()
  const membership = toMembershipPlan(subscriptionResponse)

  return useQuery({
    queryKey: getDowngradePlansQueryKey(metro_id ?? 0),
    queryFn: fetchDowngradePlans,
    select: (data) => data.data?.packages ?? [],
    enabled: Boolean(
      userId && !isExempt && membership.interval === MembershipInterval.year
    ),
  })
}

export const getStudentEnrollmentsQueryKey = (user_id: number) =>
  ['Student Enrollments', { user_id }] as const

export type StudentEnrollmentsQuery = ReturnType<
  typeof getStudentEnrollmentsQueryKey
>

export const useQueryStudentEnrollments = (isEnabled = true) => {
  const userId = useUserId()
  return useQuery({
    queryKey: getStudentEnrollmentsQueryKey(userId ?? 0),
    queryFn: fetchStudentEnrollments,
    enabled: Boolean(userId) && isEnabled,
  })
}

useQueryStudentEnrollments.getKey = getStudentEnrollmentsQueryKey
useQueryStudentEnrollments.fetcher = fetchStudentEnrollments

const getEBTEnrollmentsQueryKey = () => ['EBT Enrollments']

export type EBTEnrollmentsQuery = ReturnType<typeof getEBTEnrollmentsQueryKey>

export const useQueryEBTEnrollments = (isEnabled = true) => {
  const userId = useUserId()
  return useQuery({
    queryKey: getEBTEnrollmentsQueryKey(),
    queryFn: fetchEBTEnrollments,
    enabled: Boolean(userId) && isEnabled,
  })
}

useQueryEBTEnrollments.getKey = getEBTEnrollmentsQueryKey
useQueryEBTEnrollments.fetcher = fetchEBTEnrollments
