import { isOnServer } from '@shared/constants/util'

export const setItem = (item: string, val: string) => {
  if (isOnServer()) return
  try {
    window.localStorage.setItem(item, val)
  } catch (err) {
    // no op
  }
}

export const getItem = (item: string) => {
  if (isOnServer()) return
  try {
    return window.localStorage.getItem(item)
  } catch (err) {
    // no op
    return null
  }
}

export const removeItem = (item: string) => {
  if (isOnServer()) return
  try {
    return window.localStorage.removeItem(item)
  } catch (err) {
    // no op
  }
}

export const clear = () => {
  if (isOnServer()) return
  try {
    window.localStorage.clear()
  } catch (err) {
    // no op
  }
}
