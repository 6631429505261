// GCP_REGION is a run time env var, so it is not in a .env file nor should it be, since the server can take runtime env vars
export const gcpRegion = () => process.env.GCP_REGION

export const environment = () => process.env.NEXT_PUBLIC_ENV

export const isLocal = environment() === 'local'

export const isProduction = environment() === 'production'

export const isRunningTests = process.env.NODE_ENV === 'test'
