import { getQueryClient } from '@/utils/dataFetching/reactQuery/SegwayQueryClient'
import { type MutationKey } from '@tanstack/react-query'

export const getMutationCacheData = <T>(queryKey: string) => {
  const queryClient = getQueryClient()
  const mutationCaches = queryClient.getMutationCache()
  const mutations = mutationCaches.getAll()
  const targetMutation = mutations?.find(
    (cache) => cache?.options?.mutationKey?.[0] === queryKey
  )

  // React Query cache data has a default type of unknown so it's ok to cast here
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return targetMutation?.state?.data as T
}

export const getMutationCacheStatus = (mutationKey: MutationKey) =>
  getQueryClient().getMutationCache()?.find({ mutationKey })?.state?.status
