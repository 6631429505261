export enum VisaBenefitType {
  Traditional = '1',
  Signature = '2',
  Infinite = '3',
}
export interface VisaBenefitTier {
  id?: Maybe<VisaBenefitType>
  name?: Maybe<string>
  durationMonths?: Maybe<number>
  durationText?: Maybe<string>
  discountedDurationMonths?: Maybe<number>
  discountedDurationText?: Maybe<string>
  totalDurationMonths?: Maybe<number>
  totalDurationText?: Maybe<string>
  existingDurationMonths?: Maybe<number>
  existingDurationText?: Maybe<string>
  imgUrl?: Maybe<string>
  fallbackUrl?: Maybe<string>
  isMax?: Maybe<boolean>
}
export type VisaBenefitTiers = {
  [P in VisaBenefitType]: VisaBenefitTier
}
export type VisaBenefitFailureStatus = 'ineligible' | 'already_redeemed'
export type VisaBenefitStatus =
  | 'pending'
  | 'enrolled'
  | 'eligible'
  | VisaBenefitFailureStatus

interface VisaPromoMetadata {
  display_description?: Maybe<string>
  display_name?: Maybe<string>
  terms_and_conditions?: Maybe<string>
}
export interface VisaBenefitDetails {
  card_last_four?: Maybe<string>
  customer_id?: Maybe<string>
  correlation_id?: Maybe<string>
  status?: VisaBenefitStatus
  token?: Maybe<string>
  type?: Maybe<VisaBenefitType>
  user_type?: Maybe<'new' | 'existing' | 'upgrade' | 'downgrade' | 'sidegrade'>
  promo_id?: Maybe<string>
  promo_issuer?: Maybe<string>
  promo_metadata?: Maybe<VisaPromoMetadata>
  issuer_logo_url?: Maybe<string>
}
