import { routes } from '@shared/constants/routes'
import { resetAnalytics } from '@/analytics/utils'
import { clear } from '@/utils/localStorage'
import { isOnServer } from '@shared/constants/util'
import { getQueryClient } from '@/utils/dataFetching/reactQuery/SegwayQueryClient'
import { removeGuestCookies } from '@/context/GuestUserContext'
import {
  type LogoutOptions,
  getAuthClient,
} from '@/utils/authentication/client'
import { csDeleteStoreParams } from '@/lib/contentsquare'
import Bugsnag from '@bugsnag/js'

export const resetUser = ({ shouldRedirect = true } = {}) => {
  if (isOnServer()) return
  removeGuestCookies()
  resetAnalytics()
  csDeleteStoreParams()
  getQueryClient().clear()
  clear()
  Bugsnag.setUser()
  Bugsnag.clearMetadata('user')
  if (shouldRedirect) window.location.href = routes.LOGOUT.url
}

export const logout = ({ shouldRedirect = true }: LogoutOptions = {}) => {
  return getAuthClient().logout({ shouldRedirect })
}
