import { getCookie } from '@/utils/cookies'
import {
  SHIPT_RESTRICTION_SERVER_COOKIE,
  SHIPT_PRIVACY_BROWSER_COOKIE,
  SHIPT_PRIVACY_COOKIE_VALUE,
  SHIPT_PRIVACY_SERVER_COOKIE,
  SHIPT_RESTRICTION_BROWSER_COOKIE,
} from '@shared/constants/dataRights'
import { isOnServer } from '@shared/constants/util'
import { getQueryClient } from '@/utils/dataFetching/reactQuery/SegwayQueryClient'
import { getUser } from '@/services/User/utils'
import {
  type DataRightsSignUpValues,
  type DataRightsTrait,
  type RegulationCategoryStateAndReason,
} from '@/services/DataPrivacy/types'
import { type GetServerSidePropsContext } from 'next'

export const optOutBrowserSignalIsActive = () =>
  !isOnServer() && window.navigator.globalPrivacyControl

export const optOutBrowserCookieIsActive = () =>
  Boolean(getCookie(SHIPT_PRIVACY_BROWSER_COOKIE))

export const optOutRestrictionsCookieIsActive = (
  ssrContext?: GetServerSidePropsContext
) => {
  if (ssrContext) {
    return Boolean(ssrContext.req.cookies[SHIPT_RESTRICTION_SERVER_COOKIE])
  }
  return Boolean(getCookie(SHIPT_RESTRICTION_BROWSER_COOKIE))
}

export const userIsDoNotShareSellTA = (
  ssrContext?: GetServerSidePropsContext
) => {
  const user = getUser(getQueryClient())
  const hasPrivacyServerCookie =
    ssrContext?.req.cookies[SHIPT_PRIVACY_SERVER_COOKIE] ===
    SHIPT_PRIVACY_COOKIE_VALUE

  return Boolean(
    user?.do_not_share_sell_ta ||
      optOutBrowserCookieIsActive() ||
      optOutBrowserSignalIsActive() ||
      optOutRestrictionsCookieIsActive(ssrContext) ||
      hasPrivacyServerCookie
  )
}

export const getDataRightsTrait = (
  ssrContext?: GetServerSidePropsContext
): DataRightsTrait => ({
  do_not_share_sell_ta: userIsDoNotShareSellTA(ssrContext),
})

export const getDataRightsSignUpValues = (): DataRightsSignUpValues => {
  if (optOutBrowserSignalIsActive()) {
    return {
      do_not_share_sell_ta: true,
      do_not_share_sell_ta_source: 'web-signal',
    }
  }
  if (optOutBrowserCookieIsActive()) {
    return {
      do_not_share_sell_ta: true,
      do_not_share_sell_ta_source: 'web-form',
    }
  }
  if (optOutRestrictionsCookieIsActive()) {
    const restrictionCookieValues: RegulationCategoryStateAndReason =
      getCookie(SHIPT_RESTRICTION_BROWSER_COOKIE) || {}

    return {
      do_not_share_sell_ta: true,
      do_not_share_sell_ta_source: 'web-restriction',
      ...restrictionCookieValues,
    }
  }
  return {}
}
