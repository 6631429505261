import { formatDate } from '@/utils/date'
import { formatPromotionExpiration } from '@/utils/promotionUtils'
import { addPeriodIfNeeded } from '@/utils/formatter'
import {
  type Subscription,
  type SignupPlan,
  MembershipInterval,
  type PlanType,
} from '@/services/MembershipPlan/types'
import {
  type DeliveryPlansPackage,
  UnlimitedInterval,
  PackagePlanType,
} from '@/services/DeliveryPlans/types'
import { type Promotion } from '@/services/Campaigns/types'
import { type PaymentMethod } from '@/services/PaymentMethods/types'
import { formatPaymentMethodName } from '@/components/Wallet/utils'

export const getMembershipType = (
  interval: string,
  intervalCount = 1
): PlanType => {
  switch (interval) {
    case MembershipInterval.year:
      if (intervalCount > 1) {
        return `${intervalCount} Year`
      } else {
        return 'Annual'
      }
    case MembershipInterval.month:
      if (intervalCount > 1) {
        return `${intervalCount} Month`
      } else {
        return 'Monthly'
      }
    default:
      return 'Membership'
  }
}

// plan refers to membership options for non-subscribed users
// plans are returned from the prepaid shops service
export const getPlanInformation = (plan: DeliveryPlansPackage | null) => {
  const {
    unlimited_interval,
    total_cost_cents,
    total_base_cents,
    renewal_cost_cents,
    trial_period_days,
    plan_type,
  } = plan ?? {}

  const price = (total_cost_cents ?? 0) / 100
  const originalPrice = Number(total_base_cents) / 100
  const renewalPrice = Number(renewal_cost_cents) / 100
  const trialDays = trial_period_days ?? 0

  const canUpgrade =
    unlimited_interval !== UnlimitedInterval.ANNUAL &&
    plan_type !== PackagePlanType.STUDENT

  let name: string,
    interval: string,
    nameWithArticle: string,
    billingFrequency: string
  switch (unlimited_interval) {
    case UnlimitedInterval.MONTHLY:
      name = 'Monthly'
      nameWithArticle = 'a monthly'
      billingFrequency = 'monthly'
      interval = MembershipInterval.month
      break
    case UnlimitedInterval.ANNUAL:
    default:
      name = 'Annual'
      nameWithArticle = 'an annual'
      billingFrequency = 'annually'
      interval = MembershipInterval.year
      break
  }
  if (plan_type === PackagePlanType.STUDENT) {
    name = 'Student'
    nameWithArticle = 'a student'
  } else if (plan_type === PackagePlanType.EBT) {
    name = 'SNAP EBT'
    nameWithArticle = 'a SNAP EBT'
  }

  const isDiscounted = price < originalPrice
  const discountAmount = originalPrice - price
  const includesRefund = plan_type === PackagePlanType.DISCOUNT

  const billingDate = new Date()
  billingDate.setDate(billingDate.getDate() + trialDays)

  // set billing date to renewal date of original price
  if (isDiscounted) {
    billingDate.setFullYear(billingDate.getFullYear() + 1)
  }
  const billingDateString = formatDate(billingDate, 'MM/dd/yyyy')

  // Trims date from MM/DD/YYYY to MM/DD for use in early renewal entry points
  const expirationDate =
    plan?.text?.promo_expiration?.match(/^[^/]+\/[^/]+/)?.[0]

  return {
    ...plan,
    name,
    nameWithArticle,
    billingFrequency,
    price,
    originalPrice,
    isDiscounted,
    discountAmount,
    interval,
    canUpgrade,
    billingDate: billingDateString,
    renewalPrice,
    trialDays,
    autoRenewalTerms: plan?.text?.auto_renewal ?? {},
    includesRefund,
    expirationDate,
  }
}

// subscription refers to membership options for subscribed users
// subscriptions are returned from the subscription service
export const getSubscriptionInformation = (
  subscription: Subscription | SignupPlan
) => {
  const interval = subscription.interval ?? ''
  const intervalCount =
    'intervalCount' in subscription ? subscription.intervalCount : 1
  const planType = getMembershipType(interval, intervalCount)
  const price = subscription.price ?? 0
  const billingDate = formatDate(new Date(), 'MM/dd/yyyy')
  return { planType, price, interval, billingDate }
}

export const getAnnualMonthlyCost = (annualCost: number) => {
  return (annualCost / 12).toFixed(2)
}

/**
 * Used to calculate a users annual saving if they
 * were to upgrade to a more cost effective plan
 *
 * @param currentPlanCost Cost of current plan
 * @param currentPlanInterval Payment interval of current plan in months
 * @param upgradePlanCost Cost of upgrade plan
 * @param upgradePlanInterval Payment interval of upgrade plan in months
 * @returns {number} Total cost savings per year compared to current plan
 * @type {(currentPlanCost: number,currentPlanInterval: number,upgradePlanCost: number,upgradePlanInterval: number) => number}
 */
export const getAnnualCostSavings = (
  currentPlanCost: number,
  currentPlanInterval: number,
  upgradePlanCost: number,
  upgradePlanInterval: number
) => {
  const rawSavings =
    12 *
    (currentPlanCost / currentPlanInterval -
      upgradePlanCost / upgradePlanInterval)
  return Math.max(0, Math.floor(rawSavings))
}

export const getPromoPlanInterval = (plan: DeliveryPlansPackage) => {
  const { isDiscounted, interval } = getPlanInformation(plan)
  return isDiscounted ? `first ${interval}` : interval
}

export const getMembershipPromoData = (
  promotion: Promotion,
  hasSubscription?: boolean
) => {
  const { original_price, hide_original_price } =
    promotion?.promotion_params ?? {}
  const expiration = formatPromotionExpiration(promotion)
  const originalPrice = Number(original_price ?? 0) / 100
  const showOriginalPrice = Boolean(!hide_original_price) && originalPrice > 0

  const name = promotion?.display_name ?? ''
  const description = addPeriodIfNeeded(promotion?.display_description ?? '')
  const buttonLabel =
    promotion?.button_cta || (hasSubscription ? 'Upgrade plan' : 'Redeem offer')

  const id = `${promotion?.promotion_id}`
  const discount = Number(promotion.thresholds?.[0].effect ?? 0) / 100
  const promoPrice = originalPrice - discount

  return {
    id,
    promotion,
    expiration,
    promoPrice,
    originalPrice,
    showOriginalPrice,
    name,
    description,
    buttonLabel,
  }
}

const isNativePay = (paymentMethod: PaymentMethod) => {
  if (!paymentMethod) return false
  return paymentMethod.option === 'payment_wallet'
}

const isEbt = (paymentMethod: PaymentMethod) => {
  return Boolean(paymentMethod.option === 'credit_card' && paymentMethod.is_ebt)
}

export const getIsProhibitedPaymentTypeForMembership = (
  paymentMethod: PaymentMethod | null
) => {
  if (!paymentMethod) return true
  return isNativePay(paymentMethod) || isEbt(paymentMethod)
}

export const getProhibitedPaymentTypeCopy = (
  paymentMethod: PaymentMethod | null
) => {
  if (!paymentMethod) return undefined
  const baseString = 'cannot be used to purchase a Shipt membership'
  if (isEbt(paymentMethod)) {
    return `An EBT card ${baseString}`
  }
  if (isNativePay(paymentMethod)) {
    return `${formatPaymentMethodName(paymentMethod)} ${baseString}`
  }
  return undefined
}
