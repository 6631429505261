import { useMemo } from 'react'
import { fastlyAllowedHosts, fastlyLoader } from '@/elements/Image/utils'
// eslint-disable-next-line no-restricted-imports -- Needed for loader for NextImage
import { type ImageProps } from 'next/image'

export const useImageLoader = (imgSrc: ImageProps['src']) => {
  const loader = useMemo(() => {
    // If the imgSrc is not a string, it is a StaticImport. Use the default loader.
    if (typeof imgSrc !== 'string') return undefined
    try {
      const url = new URL(imgSrc)
      if (fastlyAllowedHosts.has(url.hostname)) {
        // Use the Fastly loader if the hostname is in the fastlyAllowedHosts set
        return fastlyLoader
      }
      // If the hostname is not in the fastlyAllowedHosts set, use the default loader
      return undefined
    } catch {
      // Non-url strings (paths without domain) should use the default loader
      return undefined
    }
  }, [imgSrc])

  return loader
}
