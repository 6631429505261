import { setCookie, getCookie } from '@/utils/cookies'

export const VISA_ENROLLMENT_KEY = 's.visa.prompt'

export const setVisaEnrollmentCookie = () => {
  setCookie(VISA_ENROLLMENT_KEY, 'true', { expires: 1 })
}

export const getHasVisaEnrollmentCookie = () =>
  Boolean(getCookie(VISA_ENROLLMENT_KEY))
