import { useHandleDialog } from '@/state/Dialog/useHandleDialog'

export const useDetectPopState = () => {
  const { closeDialog } = useHandleDialog()

  const detectPopState = () => {
    window.onpopstate = () => {
      closeDialog()
    }
  }

  return detectPopState
}
