/**
 * Removes null and undefined values from an object. Does not recursively prune children objects.
 * @param flatObject
 */

export const removeNilValues = (flatObject: Record<PropertyKey, unknown>) => {
  return Object.fromEntries(
    Object.entries(flatObject).filter(
      ([, value]) => value !== null && value !== undefined
    )
  )
}
