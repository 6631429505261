import { useEffect } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { experimentsAtom } from '@/state/Experiments/atoms'
import { useQueryExperiments } from '@/services/Experiments/queries'
import { logError } from '@/utils/logger'
import { type ExperimentValue } from '@/services/Experiments/types'

export const useLoadExperimentsIntoAtom = () => {
  const setExperiments = useSetAtom(experimentsAtom)
  const {
    data: experiments,
    isSuccess,
    isFetched,
    isPending,
  } = useQueryExperiments()

  // hydrate jotai atom from hydrated experiments query
  useHydrateAtoms([
    [experimentsAtom, { isFetched, isSuccess, isPending, data: experiments }],
  ])

  useEffect(() => {
    setExperiments({ isFetched, isSuccess, isPending, data: experiments })
  }, [setExperiments, isFetched, isSuccess, isPending, experiments])

  return null
}

export const useSyncExperimentsWithGlobalVariable = () => {
  const { data: variations, isPending } = useAtomValue(experimentsAtom)

  useEffect(() => {
    if (!isPending && variations) {
      try {
        Object.entries(variations).forEach(([key, value]) => {
          if (value.value) {
            // e.g. __SEGWAY_EXPERIMENT_shipt_gpt_v2='control'
            window[`__SEGWAY_EXPERIMENT_${key}`] = value.value
          }
        })
      } catch (e) {
        // dont wanna accidentally bring down the app
        logError(
          new Error('Unable to set experiment global variables'),
          {},
          {
            cause: e,
          }
        )
      }
    }
  }, [isPending, variations])
}

declare global {
  interface Window {
    [key: `__SEGWAY_EXPERIMENT_${string}`]: ExperimentValue
  }
}
