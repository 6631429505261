import { type ReactNode, createContext, useContext, useMemo } from 'react'
import { useHydrateAtoms } from 'jotai/utils'
import { routeDataAtom } from '@/state/RouteData/atoms'
import { type PageRouteData } from '@/utils/setPageProps'
import { routes, type RouteName } from '@shared/constants/routes'

export const CurrentRouteDataContext = createContext<PageRouteData | null>(null)

export const IsPageRoutesDataContext = createContext<IsPageRoutes | null>(null)
type IsPageRoutes = Record<RouteName, boolean>

export const RouteDataContextProvider = ({
  children,
  routeData,
}: {
  children: ReactNode
  routeData: PageRouteData
}) => {
  useHydrateAtoms([[routeDataAtom, routeData]], {
    dangerouslyForceHydrate: true,
  })

  const isPageRoutes = useMemo(() => {
    return Object.keys(routes).reduce<IsPageRoutes>((acc, routeName) => {
      return { ...acc, [routeName]: routeName === routeData?.routeName }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- cast as IsPageRoutes
    }, {} as IsPageRoutes)
  }, [routeData?.routeName])
  return (
    <CurrentRouteDataContext.Provider value={routeData}>
      <IsPageRoutesDataContext.Provider value={isPageRoutes}>
        {children}
      </IsPageRoutesDataContext.Provider>
    </CurrentRouteDataContext.Provider>
  )
}

export const useCurrentRouteDataContext = () => {
  const context = useContext(CurrentRouteDataContext)
  if (context === null) {
    throw new Error(
      'useCurrentRouteDataContext must only be used within a CurrentRouteDataContextProvider'
    )
  }
  return context
}
