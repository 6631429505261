import {
  type ServingAddressDetailsResponse,
  type ShoppingStoresResponse,
  type ShoppingStoreDetails,
  type ServingStoreLocationsResponse,
} from '@/services/ShoppingStore/types'

export const structureShoppingStores = (
  is_pickup: boolean,
  store_locations?: ServingStoreLocationsResponse[]
): ShoppingStoreDetails[] => {
  if (!store_locations?.length) return []
  return (
    store_locations.map((store_location) => ({
      name: store_location?.store.name,
      id: store_location?.store_id ?? 0,
      store_location_id: store_location?.store_location_id ?? 0,
      store_location_name: store_location.name ?? '',
      metro_id: store_location?.metro_id ?? 0,
      image: store_location?.store.circular_image_url ?? '',
      circular_image: store_location?.store.circular_image_url ?? '',
      background_color: store_location?.store.background_color,
      description: store_location?.store.description,
      is_pickup,
      recently_launched: store_location?.recently_launched ?? false,
      next_delivery_window: store_location?.next_delivery_window ?? '',
      address_street_one: store_location?.address_street_one ?? '',
      address_city_state_zip: `${store_location?.address_city}, ${store_location?.address_state} ${store_location?.address_zip_code}`,
      alcohol_available: store_location?.allows_marketplace_alcohol ?? false,
      rewards_program: store_location?.has_loyalty_program ?? false,
      long_description: store_location?.store.long_description ?? '',
      context_value: `l${store_location?.store_location_id ?? 0}_m${
        store_location?.metro_id ?? 0
      }_s${store_location?.store_id ?? 0}`,
      context_allows_snap: Boolean(store_location.context_allows_snap),
      distance_amount: store_location?.distance_amount ?? '',
      distance_unit: store_location?.distance_unit ?? '',
      recommended: store_location.recommended,
      preferred: store_location.preferred,
      selection_type: store_location.selection_type ?? '',
      is_alcohol_pickup_allowed: Boolean(store_location.allows_pickup_alcohol),
      store_shelf_pricing: Boolean(store_location.store.store_shelf_pricing),
      requires_service_fee: Boolean(store_location.store.requires_service_fee),
    })) ?? []
  )
}

export const normalizeStoresResponse = (
  storeResponse: ServingAddressDetailsResponse
): ShoppingStoresResponse => {
  const delivery_stores = structureShoppingStores(
    false,
    storeResponse?.store_locations
  )

  const pickup_stores = structureShoppingStores(
    true,
    storeResponse?.pickup_locations ?? []
  )

  return {
    delivery_stores,
    pickup_stores,
    preferred_locations_not_available:
      storeResponse.preferred_locations_not_available,
    delivery_store_counts: storeResponse?.delivery_store_counts ?? [],
  }
}
