// disable routing rules cause this is the pathnames file!
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable shipt/no-hardcoded-routes */
// THIS FILE IS AUTO-GENERATED. DO NOT EDIT THIS FILE DIRECTLY.
// RUN yarn update:pathnames TO UPDATE THIS FILE
const pageRoutes = {
  '/about': true,
  '/activate-target-account': true,
  '/ambassador': true,
  '/auth/error': true,
  '/blog': true,
  '/blog/': true,
  '/categories': true,
  '/categories/': true,
  '/cities': true,
  '/cms/module/': true,
  '/cms/page': true,
  '/community': true,
  '/contact': true,
  '/corporate': true,
  '/delivery': true,
  '/discover': true,
  '/driver': true,
  '/email-verification-required': true,
  '/gift': true,
  '/hi': true,
  '/ladderup': true,
  '/legal': true,
  '/login': true,
  '/logout': true,
  '/lp': true,
  '/offer': true,
  '/offer-terms-for-navyfederal': true,
  '/offer-terms-for-visa': true,
  '/onepass': true,
  '/partner': true,
  '/password-reset': true,
  '/pricing': true,
  '/privacy/driver-intake-form': true,
  '/privacy/intake-form': true,
  '/promotions/': true,
  '/redeem': true,
  '/secure/add-gift-card-payment': true,
  '/secure/add-membership-payment': true,
  '/secure/add-payment': true,
  '/secure/choose-membership-payment': true,
  '/shipt-account-activation': true,
  '/shop': true,
  '/shop/account/accessibility': true,
  '/shop/account/addresses': true,
  '/shop/account/advanced-security': true,
  '/shop/account/data-privacy': true,
  '/shop/account/dietary-preferences': true,
  '/shop/account/notifications': true,
  '/shop/account/orders': true,
  '/shop/account/orders/': true,
  '/shop/account/orders/substitution-preferences/': true,
  '/shop/account/preferred': true,
  '/shop/account/profile': true,
  '/shop/account/rewards': true,
  '/shop/account/wallet': true,
  '/shop/branch': true,
  '/shop/branch/u': true,
  '/shop/buy_again': true,
  '/shop/cart': true,
  '/shop/cart/loading': true,
  '/shop/checkout': true,
  '/shop/choose-membership': true,
  '/shop/coupon/': true,
  '/shop/coupons': true,
  '/shop/deals': true,
  '/shop/delivery-options': true,
  '/shop/featured-promotions/': true,
  '/shop/guest-welcome': true,
  '/shop/landing-page/': true,
  '/shop/landing-page/for-you': true,
  '/shop/lists': true,
  '/shop/lists/': true,
  '/shop/membership-dashboard': true,
  '/shop/membership-dashboard/cancellation': true,
  '/shop/order-confirmation/': true,
  '/shop/products/': true,
  '/shop/redeem': true,
  '/shop/redeem/login': true,
  '/shop/redeem/payment': true,
  '/shop/retailers-loading': true,
  '/shop/search': true,
  '/shop/special-requests': true,
  '/shop/stores': true,
  '/shop/stores/landing-page/': true,
  '/shop/stores/search': true,
  '/shop/substitution-preferences': true,
  '/shopper': true,
  '/shopper-already-applied': true,
  '/signup': true,
  '/sitemap-index': true,
  '/sitemap-index/': true,
  '/stores': true,
  '/student': true,
  '/target-circle-360': true,
  '/target-circle-360/check-email': true,
  '/up/': true,
  '/usbank': true,
  '/visa': true,
  '/welcome': true,
}

module.exports = { pageRoutes }
