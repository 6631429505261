import { useUser as useAuth0User } from '@auth0/nextjs-auth0/client'
import { hasLegacyClientAuthData } from '@/utils/authentication/client/legacy/utils'
import { shouldUseNewAuth } from '@/utils/authentication/utils'
import { useServerSsrContext } from '@/context/ServerSsrContextProvider'
import { isOnServer } from '@shared/constants/util'
import { cookieName } from '@shared/constants/auth'
import { customClaims } from '@/serverUtils/auth/constants'

export enum SessionType {
  Legacy = 'legacy',
  AuthV2 = 'authv2',
}

/**
 * Wraps the auth0 useUser hook to provide details to the front-end
 * about session state. isSessionLoading is true whenever session
 * details are being fetched from the NextJS backend. isSessionValid
 * is only true when isSessionLoading is false and there is session data.
 *
 * For now, this hook is only useful on the front-end
 * @returns Information regarding an auth0 session on the CLIENT
 */
export const useSession = () => {
  const ssrContext = useServerSsrContext()
  const { user, isLoading, error, checkSession } = useAuth0User()
  if (!shouldUseNewAuth(ssrContext)) {
    // If using legacy auth, use hasLegacyClientAuthData()
    const isSessionValid = isOnServer()
      ? Boolean(ssrContext?.req?.cookies?.[cookieName])
      : hasLegacyClientAuthData()
    return {
      sessionData: undefined,
      sessionType: SessionType.Legacy,
      error: undefined,
      isSessionLoading: false,
      isSessionValid,
      isFullyAuthenticated: isSessionValid,
      checkSession: () => Promise.resolve(),
    }
  }
  return {
    sessionData: user,
    sessionType: SessionType.AuthV2,
    error,
    isSessionLoading: isLoading,
    isSessionValid: !isLoading && Boolean(user),
    checkSession,
    isFullyAuthenticated: !isLoading && Boolean(user?.[customClaims.ONBOARDED]),
  }
}
