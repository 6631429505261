import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useQueryClient } from '@tanstack/react-query'
import { fetchUserQuery as fetchUserQueryWithQueryClient } from '@/services/User/queries'
import { UserQuery } from '@/services/User/constants'
import {
  type OauthTokenAPI,
  type User,
  type UserCredentials,
} from '@/services/User/types'
import {
  type ShoppingStore,
  type ShoppingStoreParams,
} from '@/services/ShoppingStore/types'
import { useGuestUserContext } from '@/context/GuestUserContext'
import { DELIVERY_TYPES } from '@/constants/global'
import { getUserZip } from '@/services/User/utils'
import { isValidZip } from '@/utils/validator'
import { useMembership } from '@/components/Membership/useMembership'
import { useSession } from '@/utils/authentication/hooks'
import { trackLoginCompleted } from '@/analytics/users'
import { GuestHasCartQuery } from '@/constants/ungatedParams'
import { getAddresses } from '@/services/Addresses/utils'
import { useMutationFetchJWT } from '@/services/Authentication/mutations'
import { useMutationSendCodeToChannel } from '@/services/TwoFactorAuth/mutations'
import { useQueryTwoFactorAuthChannels } from '@/services/TwoFactorAuth/queries'
import { isErrorResponseType } from '@/utils/dataFetching/utils'
import { getQueryClient } from '@/utils/dataFetching/reactQuery/SegwayQueryClient'
import { customClaims } from '@/serverUtils/auth/constants'
import { useHandleDialog } from '@/state/Dialog/useHandleDialog'
import { useCurrentRouteDataContext } from '@/context/RouteDataContext'
import { DynamicTwoFactorVerifyCodeModal } from '@/components/Modal/TwoFactorVerifyCodeModal/DynamicTwoFactorVerifyCodeModal'

export const useUser = () => {
  const queryClient = useQueryClient()
  return queryClient?.getQueryData<User>([UserQuery])
}

export const useUserHasPlacedFirstOrder = () => {
  return Boolean(useUser()?.has_placed_first_order)
}

export const useUserId = () => useUser()?.id || null

export const useMetroId = (): number => useUser()?.metro_id ?? 0

export const useUserName = (): string => useUser()?.name ?? ''

export const useUserEmail = (): string => useUser()?.email ?? ''

export const useUserPhone = (): string => useUser()?.phone ?? ''

export const useUserDefaultShoppingAddressId = (): number =>
  useUser()?.default_shopping_address_id ?? 0

export const useIsPickup = (): boolean => {
  const user = useUser()
  const { guestStore } = useGuestUserContext() ?? {}
  return (
    user?.order_delivery_type === DELIVERY_TYPES.PICKUP ||
    Boolean(guestStore?.is_pickup)
  )
}

export const useIsUserExempt = (): boolean => {
  // this hook drives the exempt experience, but Circle360 now takes priority
  const { isCircle360 } = useMembership()
  return Boolean(useUser()?.exempt) && !isCircle360
}

export const useUserStoreLocationId = (): number => {
  const user = useUser()
  const { guestStore } = useGuestUserContext() ?? {}
  return user?.store_location_id || guestStore?.store_location_id || 0
}

export const useStoreId = (): number => {
  const userStoreId = useUser()?.store_id ?? 0
  const { guestStore } = useGuestUserContext() ?? {}
  return userStoreId || guestStore?.id || 0
}

export const useUserZip = (): string => {
  const user = useUser()
  return user ? getUserZip(user) : ''
}

export const useUserStore = (): Maybe<ShoppingStore> => useUser()?.store ?? null

export const useUserStoreName = (): string => {
  const userStore = useUserStore()
  const { guestStore } = useGuestUserContext() ?? {}
  return userStore?.name || guestStore?.name || ''
}

export const useUserStoreImage = (): string => {
  const { circular_image_url, image } = useUserStore() ?? {}
  const { guestStore } = useGuestUserContext() ?? {}
  const guestStoreImage = guestStore?.circular_image || guestStore?.image

  return useMemo(
    () => circular_image_url || image || guestStoreImage || '',
    [circular_image_url, guestStoreImage, image]
  )
}

export const useIsMember = () => {
  const isExempt = useUser()?.exempt
  const { id } = useMembership(false)
  return Boolean(isExempt || id)
}

export const useIsGuestUser = () => {
  const { guestAddress } = useGuestUserContext() ?? {}

  const { isSessionValid, sessionData } = useSession()
  /*
  We need this check below since a user can be on welcome page from auth0 flow, 
  not have created an account and still have the session valid due to auth cookie. 
  So technically this check is to make sure a user is onBoarded from auth0 perspective or be authenticated.
  */
  const isFullyAuthenticated = sessionData
    ? Boolean(sessionData[customClaims.ONBOARDED])
    : isSessionValid

  return useMemo(() => {
    return !isFullyAuthenticated && isValidZip(guestAddress?.zip_code ?? '')
  }, [guestAddress, isFullyAuthenticated])
}

/**
 * Gets whether the user is a visitor or not
 * @returns true when a user is not logged in and is not a guest user, or visiting a non-auth route (if checkRouteData is true)
 */
export const useIsVisitor = ({ checkRouteData = true } = {}) => {
  const userId = useUserId()
  const isGuestUser = useIsGuestUser()
  const currentRouteData = useCurrentRouteDataContext()

  return useMemo(() => {
    return Boolean(
      (!userId && !isGuestUser) ||
        (checkRouteData && !currentRouteData?.authentication)
    )
  }, [isGuestUser, checkRouteData, currentRouteData, userId])
}

const defaultError = {
  message: 'Something went wrong. Please try again.',
}

export const useLogin = () => {
  const { openDialog } = useHandleDialog()
  const router = useRouter()
  const { clearGuestCookie } = useGuestUserContext() ?? {}
  const fetchUserQuery = useFetchUserQuery()
  const { mutateAsync: fetchJWT, error: jwtError } = useMutationFetchJWT()
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [isTwoFactorLogin, setIsTwoFactorLogin] = useState(false)
  const { data } = useQueryTwoFactorAuthChannels(isTwoFactorLogin)
  const { mutate: sendCodeToChannel, isPending: isSendingCodeToChannel } =
    useMutationSendCodeToChannel()
  const defaultChannel = data?.channels?.find((channel) => channel.default)
  const channelId = defaultChannel?.id ?? ''

  useEffect(() => {
    const handleTwoFactorAuth = () => {
      return sendCodeToChannel(channelId, {
        onSuccess: () =>
          openDialog(DynamicTwoFactorVerifyCodeModal, { channelId }),
      })
    }
    if (isTwoFactorLogin && channelId) handleTwoFactorAuth()
  }, [isTwoFactorLogin, channelId, sendCodeToChannel, openDialog])

  const login = async (
    { username, password }: UserCredentials,
    proofOfWorkCount: number | undefined
  ) => {
    setIsLoggingIn(true)

    let oAuthData: OauthTokenAPI
    try {
      oAuthData = await fetchJWT({ username, password, proofOfWorkCount })
      // We want to clear mutationCache to fire /track calls again
      // when a user converts from being a guest to auth user
      getQueryClient().getMutationCache()?.clear()
      if (router.query.guest !== GuestHasCartQuery) {
        clearGuestCookie?.()
      }
    } catch (error) {
      const err =
        (isErrorResponseType(error) && error.response.data.error) ||
        defaultError
      throw new Error(err.message)
    } finally {
      setIsLoggingIn(false)
    }

    if ('scope' in oAuthData) {
      return setIsTwoFactorLogin(true)
    }

    try {
      await fetchUserQuery()
      trackLoginCompleted('')
    } finally {
      setIsLoggingIn(false)
    }
  }

  return { login, isSendingCodeToChannel, isLoggingIn, error: jwtError }
}

export const useFetchUserQuery = () => {
  const queryClient = useQueryClient()

  return useCallback(
    () => fetchUserQueryWithQueryClient(queryClient),
    [queryClient]
  )
}

export const useUserAddressParams = ({
  zip = '',
} = {}): ShoppingStoreParams => {
  const router = useRouter()
  const isGuestUser = useIsGuestUser()
  const queryClient = useQueryClient()
  const addressId =
    useUserDefaultShoppingAddressId() || getAddresses(queryClient)?.[0]?.id
  const queryParamZip = String(router?.query?.zip ?? '')
  const guestAddress = useGuestUserContext()?.guestAddress
  const guestExperience =
    (Boolean(queryParamZip || zip) || isGuestUser) && !addressId

  return guestExperience
    ? {
        address_line_1: guestAddress?.street1,
        address_city: guestAddress?.city,
        address_state: guestAddress?.state,
        address_zip_code: guestAddress?.street1
          ? guestAddress?.zip_code
          : zip || guestAddress?.zip_code || queryParamZip,
      }
    : { address_id: addressId }
}
