import { type SignupPlansResponse } from '@/services/MembershipPlan/types'
import { apiGet } from '@/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type DeliveryPlanQueryKey,
  type SignupPlansKey,
} from '@/services/DeliveryPlans/queries'
import { type FetchPackagesResponse } from '@/services/DeliveryPlans/types'

export const fetchSignupPlans = (
  context: QueryFunctionContext<SignupPlansKey>
) => {
  const [, params] = context.queryKey
  const ssrContext = context.meta?.ssrContext

  let referringUrl = params.referring_url || ''
  if (!referringUrl) {
    if (ssrContext) {
      referringUrl = ssrContext.req.headers.referer || ''
    } else if (typeof document !== 'undefined') {
      referringUrl = document.referrer || ''
    }
  }

  return apiGet<SignupPlansResponse>({
    config: {
      url: 'signup_promotions/find_plans',
      params: {
        ...params,
        referring_url: referringUrl,
      },
    },
    context,
    fetcherName: 'fetchSignupPlans',
  })
}

export const fetchDeliveryPlans = (
  context: QueryFunctionContext<DeliveryPlanQueryKey>
) => {
  const [, params] = context.queryKey

  return apiGet<FetchPackagesResponse>({
    config: {
      url: 'prepaid-shops/v2/packages',
      params: params.storeParams,
    },
    context,
    fetcherName: 'fetchDeliveryPlans',
  })
}
