import { isOnServer } from '@shared/constants/util'
import { medallia } from '@/lib/medallia'
import {
  getPageData,
  shouldDisableAnalytics,
  shouldSendToGoogle,
  urlPathnameToLocationName,
} from '@/analytics/utils'
import { getTrackOptions } from '@/analytics/trackingQueue'
import ShiptAnalytics, {
  AnalyticsEvent,
  getAnonymousId,
} from '@shipt/analytics-member-web'
import { type PageEventProperties } from '@shipt/analytics-core'
import { csTrackPageview } from '@/lib/contentsquare'
// eslint-disable-next-line no-restricted-imports -- ok to use in analytics file
import { getJotaiStore } from '@/state'
import { routeDataAtom } from '@/state/RouteData/atoms'

type Props = { pathname: string; isInitialNavigation?: boolean }

export const pageEvent = ({ pathname, isInitialNavigation = false }: Props) => {
  const matchedRoute = getJotaiStore().get(routeDataAtom)
  if (matchedRoute && !('delayMedallia' in matchedRoute)) {
    medallia.updatePageView()
  }
  if (isOnServer()) return
  if (shouldDisableAnalytics()) return

  if (!isInitialNavigation) {
    csTrackPageview(pathname, window?.location?.search)
  }

  const anonymousId = getAnonymousId()
  window.analytics?.setAnonymousId(anonymousId)

  const pageName = matchedRoute?.trackingName || pathname
  const properties: PageEventProperties = {
    ...getPageData(),
    name: urlPathnameToLocationName(),
  }
  // use valid placeholder event to enable device mode integrations
  const placeHolderEvent = AnalyticsEvent.ElementClicked
  const options = getTrackOptions({ eventName: placeHolderEvent })

  if (shouldSendToGoogle(placeHolderEvent)) {
    window.dataLayer.push({
      event: 'Loaded a Page',
      ...properties,
      ...(options?.userId && { userId: options.userId }),
      segmentAnonymousId: anonymousId,
    })
  }
  ShiptAnalytics.page(pageName, properties, options)
  window.analytics?.page(pageName, properties, options)
}
