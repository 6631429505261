import ReactModal from 'react-modal'
import { transparentize } from 'polished'
import { isOnServer } from '@shared/constants/util'

export const setupReactModal = () => {
  if (isOnServer()) return

  const rootElement = document.getElementById('__next')
  // this moves the aria-hidden="true" from the body to the div#root
  // by doing so, screenreaders will be able to access the modal content but not the main document content
  if (rootElement) {
    ReactModal.setAppElement(rootElement)

    ReactModal.defaultStyles = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: transparentize(0.5, 'black'),
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000000,
      },
    }
  }
}
