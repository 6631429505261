import { privacy } from '@/components/Footers/MarketingFooter/constants'
import { socialLinks } from '@/constants/socialLinks'
import { staticColors } from '@/theme/staticColors'
import { themeTokens } from '@/theme/tokens'
import { shiptLight } from '@shipt/design-system-themes'
import { cmsRoutes, routes } from '@shared/constants/routes'
import { LEGAL_LINKS } from '@/constants/legalLinks'

const { Figma, ...restShiptLight } = shiptLight
export const ShiptStaticData = {
  displayName: 'Shipt',
  theme: {
    ...staticColors,
    headerBanner: '#502D91',
    primary: '#241239',
    primaryOnLight: '#241239',
    primaryContrast: '#FFFFFF',
    ...restShiptLight,
    ...themeTokens,
  },
  images: {
    favicon:
      'https://object-storage.shipt.com/web/assets/favicon64-rebrand_90963dce1c6ced33364dae53812fcdb0.png',
    on_sale:
      'https://object-storage.shipt.com/web/assets/deals_de1cac8144baa0625c43f09e1a5c1c11.png',
    buy_again:
      'https://object-storage.shipt.com/web/assets/buyAgain-rebrand-final_4643d2abba571346e49252317d3cc81b.png',
    footer_logo:
      'https://object-storage.shipt.com/web/assets/headerLogo-new.svg',
    header_logo:
      'https://object-storage.shipt.com/web/assets/headerLogo-new.svg',
    login_photo:
      'https://object-storage.shipt.com/web/assets/get-started-hero.png',
    signup_photo:
      'https://object-storage.shipt.com/web/assets/get-started-hero.png',
  },
  footerData: {
    sections: [
      {
        links: [
          {
            url: routes.GIFT.url,
            name: 'Gift Cards',
          },
          {
            url: 'https://help.shipt.com',
            name: 'Help',
            external: true,
          },
          {
            url: `${routes.SIGN_UP.url}?referrerUrl=https%3A%2F%2Fwww.shipt.com`,
            name: 'Sign up',
          },
        ],
        title: 'Using Shipt',
      },
      {
        links: [
          {
            url: 'https://www.shipt.com/about/',
            name: 'About us',
            external: true,
          },
          {
            url: 'https://partner.shipt.com/join',
            name: 'Partner with us',
            external: true,
          },
          {
            url: `${routes.CORPORATE.url}/careers/`,
            name: 'Careers',
          },
          {
            url: 'https://corporate.shipt.com/news',
            name: 'Press',
            external: true,
          },
          {
            url: `${routes.DELIVERY.url}/grocery-delivery`,
            name: 'Grocery delivery',
          },
        ],
        title: 'Resources',
      },
      {
        links: [
          {
            url: cmsRoutes.SHOPPER.BE_A_SHOPPER,
            name: 'Get paid to shop',
          },
          {
            url: routes.BLOG.url,
            name: 'Blog',
          },
          {
            url: routes.CONTACT.url,
            name: 'Contact us',
          },
          {
            url: 'https://www.shipt.com/legal/trustandsafety',
            name: 'Trust and safety',
            external: true,
          },
        ],
        title: 'Community',
      },
      {
        links: privacy,
        title: 'Policies',
      },
    ] as const,
    socialLinks: [
      {
        url: socialLinks.FACEBOOK,
        name: 'facebook',
        opensInNewTab: false,
      },
      {
        url: socialLinks.INSTAGRAM,
        name: 'instagram',
        opensInNewTab: false,
      },
      {
        url: socialLinks.PINTEREST,
        name: 'pinterest',
        opensInNewTab: false,
      },
      {
        url: socialLinks.TWITTER,
        name: 'twitter',
        opensInNewTab: false,
      },
    ],
  },
  authFlowData: {
    login_heading: 'Welcome Back!',
    signup_heading: 'Let’s get started',
    login_subheading: 'Your day is about to get a whole lot easier.',
    signup_subheading: 'Nice to meet you.',
    terms_of_service_url: LEGAL_LINKS.TERMS_OF_SERVICE,
    privacy_policy_url: LEGAL_LINKS.PRIVACY_POLICY,
  },
  welcomeFlowData: {
    add_address_heading: 'Welcome to Shipt!',
    choose_store_heading: 'Select store',
    add_address_subheading:
      'Tell us where to deliver and we’ll find stores nearby.',
    choose_store_subheading:
      'Choose a nearby store you’d like to shop. You can switch at any time.',
  },
} as const

export type ShiptTheme = typeof ShiptStaticData.theme
export type AuthFlowData = {
  login_heading: string
  signup_heading: string
  login_subheading: string
  signup_subheading: string
  terms_of_service_url: string
  privacy_policy_url: string
}
