const commitSHA = process.env.NEXT_PUBLIC_COMMIT_SHA || ''

export const legacyApiGatewayURL = process.env.NEXT_PUBLIC_SHIPT_API_URL
export const apiGatewayURL = process.env.NEXT_PUBLIC_SHIPT_API_GATEWAY_URL

export const getRequestParams = (storeParams: Record<string, unknown> = {}) => {
  const params: Record<string, string> = {}

  params.segway_version = commitSHA

  for (const key in storeParams) {
    const value = storeParams[key]
    if (
      value !== null &&
      value !== undefined &&
      typeof value.toString === 'function'
    ) {
      params[key] = value.toString()
    }
  }
  return params
}
