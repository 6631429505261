export const levels = {
  error: 0,
  warn: 1,
  info: 2,
  debug: 3,
}

export type LogLevel = keyof typeof levels

export const isLogLevel = (logLevel = ''): logLevel is LogLevel =>
  Object.keys(levels).includes(logLevel)

export type LogMeta = {
  fetcherName?: string
  responseStatus?: number
  customGroupingHash?: string
} & Record<string, unknown>
