import { cmsRoutes, routes } from '@shared/constants/routes'

export const MEMBERSHIP_POPOVER_LINKS = [
  { url: routes.PRICING.url, name: 'See all plans' },
  { url: routes.STUDENT.url, name: 'Student Membership' },
  {
    url: 'https://www.shipt.com/lp/snap/',
    name: 'SNAP EBT Membership',
  },
  {
    url: 'https://www.shipt.com/gift/',
    name: 'Gift Cards',
  },
]
export const MAKE_MONEY_LINKS = [
  { url: cmsRoutes.SHOPPER.BE_A_SHOPPER, name: 'Be a shopper' },
  { url: cmsRoutes.DRIVER.BE_A_DRIVER, name: 'Be a driver' },
  { url: `${routes.PARTNER.url}/affiliate`, name: 'Be an affiliate' },
]
