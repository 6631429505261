import styled from 'styled-components'
import { IconButton } from '@shipt/design-system-buttons'
import { getBoxShadow } from '@/styles/effects'

export * from './ButtonColors'
export * from './ButtonVariants'

export const CarouselButton: typeof IconButton = styled(IconButton)`
  background-color: ${({ theme }) =>
    theme.color.background.base.neutral.subtle_00.value};
  ${getBoxShadow('lift', 'light')}

  &:active {
    box-shadow: ${({ theme }) =>
      theme.shadow.lift.light.active.value
        .map(
          ({ blur, color, spread, x, y }) =>
            `${x}px ${y}px ${blur}px ${spread}px ${color}`
        )
        .join(', ')};
  }
`

export const TransparentButton = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`
