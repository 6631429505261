import { type GetServerSidePropsContext } from 'next'
import { _createClient as createLegacyClient } from '@/utils/authentication/client/legacy/client'
import { _createClient as createAuth0Client } from '@/utils/authentication/client/auth0/client'
import { type OauthTokenAPI } from '@/services/User/types'
import { isOnServer } from '@shared/constants/util'
import { shouldUseNewAuth } from '@/utils/authentication/utils'
import { type ClientSafeSsrContext } from '@/utils/setPageProps'

export interface GetAccessTokenOptions {
  ssrContext?: GetServerSidePropsContext
  forceRefresh?: boolean
}

export interface LogoutOptions {
  shouldRedirect?: boolean
}

export interface AuthClient {
  /**
   * Returns a Promise that resolves to an access token. If necessary (or the forceRefresh option is passed),
   * the token will be refreshed and the new token will be returned.
   * @param options Options consist of the ssrContext (required on server) and a forceRefresh boolean,
   * which can be used to refresh the token before it is necessary
   * @returns a Promise that resolves to an access token
   */
  getAccessToken: (
    options?: GetAccessTokenOptions
  ) => Promise<string | undefined>
  getIsTokenExpired: () => Promise<boolean>
  logout: (options?: LogoutOptions) => Promise<void>
  /**
   * @deprecated Direct access to persisted auth data is deprecated. This will be a no-op
   * in future auth clients, and will eventually be removed.
   */
  persistence: {
    /**
     * @deprecated Direct access to persisted auth data is deprecated. This will be a no-op
     * in future auth clients, and will eventually be removed.
     */
    getAuthData: () => Promise<Partial<OauthTokenAPI>>
    /**
     * @deprecated Direct access to persisted auth data is deprecated. This will be a no-op
     * in future auth clients, and will eventually be removed.
     */
    setAuthData: (
      authData: Partial<OauthTokenAPI>,
      ssrContext?: GetServerSidePropsContext
    ) => Promise<void>
    /**
     * @deprecated Direct access to persisted auth data is deprecated. This will be a no-op
     * in future auth clients, and will eventually be removed.
     */
    deleteAuthData: () => Promise<void | undefined>
  }
}

let legacyClient: AuthClient, auth0Client: AuthClient

/**
 * The public interface for retrieving the auth client used for
 * obtaining access tokens, etc. This function will return either
 * a legacy or (eventually) auth0 client.
 * @returns The auth client, legacy or new, depending on certain
 * testing and rollout conditions
 */
export const getAuthClient = (ssrContext?: ClientSafeSsrContext) => {
  if (isOnServer() && !ssrContext) {
    throw new Error(
      'getAuthClient must be passed ssrContext when used on the server'
    )
  }

  if (!legacyClient) {
    legacyClient = createLegacyClient()
  }
  if (!auth0Client) {
    auth0Client = createAuth0Client()
  }
  if (shouldUseNewAuth(ssrContext)) {
    return auth0Client
  }
  return legacyClient
}
