import { type ReactNode, useSyncExternalStore } from 'react'

type Props = {
  children: ReactNode
  /**
   * The fallback is rendered on the server when the component is not mounted yet.
   * It is recommended to add a fallback with the same dimensions as the client rendered
   * children in order to avoid content layout shift.
   */
  fallback?: ReactNode
}

const subscribe = () => () => {}

/**
 * useHydrated
 * Use this in components when state needs to be synchronized between the server and the client
 * Wrap this condition within the ClientOnly
 * It reduces the likelihood of flickering during transitioning from server rendered content to client rendered content
 */
export const useHydrated = () => {
  return useSyncExternalStore(
    subscribe,
    () => true,
    () => false
  )
}

/**
 * ClientOnly is used to render the children only after the component is mounted.
 * This is useful for components that rely on the DOM such as using window APIs.
 * Example:
 * <ClientOnly fallback={<FallbackComponent />}>
 *  <MyComponent />
 * </ClientOnly>
 */
export const ClientOnly = ({ children, fallback = null }: Props) => {
  // ignore rule for this hook's usage
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{useHydrated() ? children : fallback}</>
}
