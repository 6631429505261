import {
  addCloudModeIntegrations,
  setDeviceModeIntegrations,
} from '@/analytics/integrations'
import ShiptAnalytics, {
  type AllAnalyticsEvents,
  type AnalyticsEvent,
  getAnonymousId,
  getCampaignInfo,
  getSessionInfo,
} from '@shipt/analytics-member-web'
import { type ShiptEventOptions } from '@shipt/analytics-core'
import { v4 as uuidv4 } from 'uuid'
import { isOnServer } from '@shared/constants/util'
import { useState } from 'react'
import {
  getGuestTrait,
  getPageData,
  getUserTraits,
  shouldDisableAnalytics,
  shouldSendToGoogle,
} from '@/analytics/utils'
import {
  handleMarvAdviceCookieExpiration,
  MARV_ADVICE_BY_CAMPAIGN,
} from '@/utils/marv'
import { getCookie } from '@/utils/cookies'
import { getUser } from '@/services/User/utils'
import { getQueryClient } from '@/utils/dataFetching/reactQuery/SegwayQueryClient'
import { getDataRightsTrait } from '@/services/DataPrivacy/utils'

export const getTrackOptions = ({
  eventName,
  traits,
}: {
  eventName: AnalyticsEvent
  traits?: Record<string, unknown>
}): Partial<ShiptEventOptions> => {
  const userData = getUser(getQueryClient())
  const { sessionId } = getSessionInfo() ?? {}
  return {
    anonymousId: getAnonymousId(),
    context: {
      linkKey: uuidv4(),
      // @ts-expect-error we want to attach an UUID to each event so that it has
      // the same UUID across the inhouse SDK and the segment SDK
      traits: {
        ...traits,
        ...(userData && {
          ...getUserTraits(userData),
        }),
        ...getDataRightsTrait(),
        ...getGuestTrait(),
      },
      campaign: getCampaignInfo(getPageData()),
      sessionId,
    },
    integrations: {
      ...setDeviceModeIntegrations(eventName),
      ...addCloudModeIntegrations(),
    },
    ...(userData?.id && { userId: String(userData.id) }),
  }
}

export const track = ({
  eventName,
  properties,
  eventOptions,
  traits,
}: AllAnalyticsEvents & {
  eventOptions?: Partial<ShiptEventOptions>
  traits?: Record<string, unknown>
}) => {
  if (isOnServer() || shouldDisableAnalytics()) return
  handleMarvAdviceCookieExpiration()
  const options = {
    ...getTrackOptions({
      eventName,
      traits,
    }),
    ...eventOptions,
  }
  const marvCookieValue = getCookie(MARV_ADVICE_BY_CAMPAIGN)

  const eventProperties: AllAnalyticsEvents['properties'] = {
    ...properties,
    ...(marvCookieValue && {
      marv_advice_by_campaign: JSON.stringify(marvCookieValue),
    }),
  }
  // https://support.google.com/tagmanager/answer/7679219?hl=en&ref_topic=7679108
  if (shouldSendToGoogle(eventName)) {
    window.dataLayer.push({ event: eventName, ...eventProperties })
  }
  ShiptAnalytics.track(eventName, eventProperties, options)
  window.analytics?.track(eventName, eventProperties, options)
}

export const useTrackingQueue = () => {
  const [eventQueue, setEventQueue] = useState<AllAnalyticsEvents[]>([])
  const trackQueue = (event: AllAnalyticsEvents) => {
    if (isOnServer()) return
    setEventQueue([...eventQueue, event])
  }
  const sendAllQueuedEvents = () => {
    if (isOnServer() || !eventQueue.length) return
    while (eventQueue.length) {
      const event = eventQueue.shift()
      if (event) track(event)
    }
  }

  return {
    eventQueue,
    trackQueue,
    sendAllQueuedEvents,
  }
}
