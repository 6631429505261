import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { fontWeights } from '@/styles/typography/constants'
import { BaseText } from '@/styles/typography/base.styled'

const { book } = fontWeights

const BodyBase = styled(BaseText)`
  font-weight: ${book};
  margin: 0;
`
/**
 * @deprecated Use { Body } from '@shipt/design-system-typography' instead
 */
export const Body0 = styled(BodyBase)`
  line-height: 1.5rem;
  font-size: 1.1875rem;
`

/**
 * @deprecated Use { Body } from '@shipt/design-system-typography' instead
 */
export const Body1 = styled(BodyBase)`
  line-height: 1.5rem;
  font-size: 1.0625rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 1.5rem;
      font-size: 1.1875rem;
    }
  `};
`
/**
 * @deprecated Use { Body } from '@shipt/design-system-typography' instead
 */
export const Body2 = styled(BodyBase)`
  line-height: 1.25rem;
  font-size: 0.9375rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 1.5rem;
      font-size: 1.0625rem;
    }
  `};
`
/**
 * @deprecated Use { Body } from '@shipt/design-system-typography' instead
 */
export const Body3 = styled(BodyBase)`
  line-height: 1rem;
  font-size: 0.8125rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 1.25rem;
      font-size: 0.9375rem;
    }
  `};
`
