import { type ComponentProps } from 'react'
import styled, { css } from 'styled-components'
// We need to import from 'next/link' here to define our component
// eslint-disable-next-line no-restricted-imports
import BaseLink from 'next/link'
import { getLinkNewTabAttributes } from '@/elements/Link/utils'

export type NextLinkProps = Omit<
  ComponentProps<typeof BaseLink>,
  'role' | 'passHref' | 'as' | 'ref'
> & { showUnderline?: boolean }

/**
 * # NextLink
 * @param children The component to cast as a link.
 * @param href The link to redirect the user to.
 *
 */
export const NextLink = ({
  children,
  showUnderline = false,
  ...restProps
}: NextLinkProps) => {
  return (
    <LinkWrapper $showUnderline={showUnderline} {...restProps} passHref>
      {children}
    </LinkWrapper>
  )
}

type Underline = 'none' | 'hover' | 'always'
/**
 * @deprecated Use { Link } from '@shipt/design-system-typography' instead
 */
export const ExternalLink = styled.a.attrs<{ opensInNewTab?: boolean }>(
  (props) => getLinkNewTabAttributes(props?.opensInNewTab || false)
)<{ opensInNewTab?: boolean; $underline?: Underline }>`
  color: ${(props) => props.theme.gray600};
  background-color: transparent;
  cursor: pointer;

  ${({ $underline = 'hover' }) => getTextDecoration($underline)};
`
const LinkWrapper = styled(BaseLink)<{ $showUnderline: boolean }>`
  ${({ $showUnderline }) =>
    !$showUnderline &&
    `
  text-decoration: none;
  `}
`

const getTextDecoration = (underline: Underline) => {
  const styles = {
    none: css`
      text-decoration: none;
    `,
    hover: css`
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    `,
    always: css`
      text-decoration: underline;
    `,
  } satisfies Record<Underline, ReturnType<typeof css>>
  return styles[underline] || styles.none
}
