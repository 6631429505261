import ClientCookies from 'js-cookie'
import { trackLoginCompleted } from '@/analytics/users'
import { isOnServer } from '@shared/constants/util'
import { loginCompletedTriggerCookieName } from '@shared/constants/auth'

export function handleAuth0Login() {
  if (isOnServer()) return
  if (ClientCookies.get(loginCompletedTriggerCookieName) === '1') {
    ClientCookies.remove(loginCompletedTriggerCookieName)
    trackLoginCompleted('authv2')
  }
}
