import { fetchUserQuery } from '@/services/User/queries'
import { type GetPageDataParams } from '@/serverUtils/ssrHelpers/createServerSidePropsHandler'
import {
  SHIPT_PRIVACY_COOKIE_VALUE,
  SHIPT_PRIVACY_SERVER_COOKIE,
  SHIPT_RESTRICTION_BROWSER_COOKIE,
  SHIPT_RESTRICTION_SERVER_COOKIE,
} from '@shared/constants/dataRights'
import { cookieOptions, secureClientOptions } from '@shared/constants/auth'
import { getGuestStoreParamsSSR } from '@/utils/dataFetching/guestStoreParams'
import { restrictionsQueryOptions } from '@/services/DataPrivacy/queries'
import safeStringify from 'safe-stable-stringify'
import type ServerCookies from 'cookies'
import { type RegulationCategoryStateAndReason } from '@/services/DataPrivacy/types'

export const fetchUserQueryAndOptOutRestrictions = async ({
  ssrContext,
  queryClient,
  isAuthenticated,
}: Pick<GetPageDataParams, 'ssrContext' | 'queryClient'> & {
  isAuthenticated: boolean
}) => {
  const user = isAuthenticated ? await fetchUserQuery(queryClient) : undefined
  const noOptOutCookie = Boolean(
    !ssrContext?.req.cookies[SHIPT_PRIVACY_SERVER_COOKIE]
  )
  if (noOptOutCookie) {
    if (user?.do_not_share_sell_ta) {
      ssrContext.req.cookies[SHIPT_PRIVACY_SERVER_COOKIE] =
        SHIPT_PRIVACY_COOKIE_VALUE
    } else if (!user?.default_shopping_address_id) {
      const { guestAddress } = getGuestStoreParamsSSR(ssrContext)
      await queryClient.prefetchQuery(
        restrictionsQueryOptions({
          ...(guestAddress?.zip_code && { zip_code: guestAddress.zip_code }),
          ...(guestAddress?.state && { state: guestAddress.state }),
        })
      )
    }
  }
  return user
}

export const setOptOutRestrictionServerCookies = (
  cookies: ServerCookies,
  regulationParams: RegulationCategoryStateAndReason
) => {
  cookies.set(
    SHIPT_RESTRICTION_SERVER_COOKIE,
    safeStringify(regulationParams),
    cookieOptions
  )
  cookies.set(
    SHIPT_RESTRICTION_BROWSER_COOKIE,
    safeStringify(regulationParams),
    secureClientOptions
  )
}
