import { type Product, type ProductAPI } from '@/services/Product/types'
import {
  normalizeProduct,
  getProductType,
  PRODUCT_TYPES,
} from '@/utils/normalizeProduct'
import {
  type Cart,
  type CartLine,
  type CartResponse,
} from '@/services/Cart/types'
import { routes } from '@shared/constants/routes'
import { GuestHasCartParam } from '@/constants/ungatedParams'
import { type ParsedUrlQuery } from 'querystring'
import { concatQueryStrings } from '@/utils/url'
import { normalizeCampaignsResponse } from '@/services/Campaigns/utils'

// For UI purposes (i.e. in the ProductQuantitySelector) we want to round the
// quantities that are saved within a custom input to reflect an accurate quantity.
// For example, we want to round custom quantities for 'by weight' products
// that don't have a custom label to the nearest 0.5 increment. For all other
// products, we just want to round to the nearest whole number.
export const roundedQuantity = (product?: Product, quantity = 1) => {
  if (product?.type === 'by weight' && !product?.hasCustomLabel) {
    return Math.round(quantity * 2) / 2
  } else {
    return Math.round(quantity)
  }
}

// Some products are sold by weight (e.g. packaged steaks, deli meats, apples,
// etc.); some of those "by weight" products also have a custom label override.
// If a product is "by weight", we want to use the Shipt-defined "unit weight"
// of that product to represent the product's singular quantity; for "normal"
// products (i.e. products not "by weight"), the singular quantity is just 1.
//
// Relevant (may need to request access): http://bit.ly/2EUDiWK
export const quantityForProductType = (product: Product) => {
  const quantity = product?.quantity ?? 0
  if (product.type === 'by weight') {
    if (product.hasCustomLabel) {
      return product.unitWeight * quantity
    } else {
      // We want to round custom quantities for 'by weight' products that
      // don't have a custom label to the nearest 0.5 increment
      return Math.round(quantity * 2) / 2
    }
  } else {
    return quantity
  }
}

export const getOptimisticCartQuantity = (
  cartProducts: Product[],
  products: Product[]
) => {
  const productsQuantity = products.reduce<number>((totalQuantity, product) => {
    const quantity =
      getProductType(product) === PRODUCT_TYPES.BY_WEIGHT ? 1 : product.quantity
    return totalQuantity + quantity
  }, 0)

  const productsIds = products.map((product) => product.id)

  return cartProducts.reduce<number>((totalQuantity, cartProduct) => {
    if (!productsIds.includes(cartProduct.id)) {
      // Shipt has decided that some "by weight" products, those *without* a
      // custom label override, are sold in half-unit increments, effectively
      // making the "unit weight" of this type of product "0.5".
      //
      // Each time a user adds one of these products to their cart, we want to
      // show the actual half-unit increment in the product grid card, but count
      // the item as 1 in the cart. For example, if a user clicks "add" 5 times on
      // this type of product in the grid view, the product card should read
      // "2.5 lbs" while the total cart quantity should have increased by 1.
      if (getProductType(cartProduct) === PRODUCT_TYPES.BY_WEIGHT) {
        return totalQuantity + 1
      }
      return totalQuantity + cartProduct.quantity
    }
    return totalQuantity
  }, productsQuantity)
}

export const flattenAndNormalizeProduct = (cartProduct: CartLine) => {
  const productAPI: ProductAPI = {
    product_id: cartProduct.product_id,
    note: cartProduct.note,
    coupon_id: cartProduct.coupon_id,
    quantity: cartProduct.qty,
    product_type: cartProduct.product_type,
    is_custom_product: cartProduct.product_type === 'CustomProduct',
    ...cartProduct.product,
  }
  return normalizeProduct(productAPI)
}

export const getWeightedProductLabel = (product: Product) => {
  if (getProductType(product) === PRODUCT_TYPES.CUSTOM_BY_WEIGHT) {
    const { unitWeight, descriptionLabels = [] } = product

    return `About ${unitWeight} lb per ${descriptionLabels[0]}`
  }

  return undefined
}

export const cartProductsForNewOrder = (shoppingCartLines: Product[]) => {
  return shoppingCartLines.map((product) => {
    const {
      id,
      name,
      note,
      isCustomProduct,
      couponId,
      onSale,
      totalSalePrice,
      totalPrice,
      universal_id,
    } = product
    const requestedQuantity = quantityForProductType(product)
    const orderLine = {
      universal_id,
      requested_product_id: id ?? 0,
      requested_qty: requestedQuantity,
      notes: note,
      price: onSale ? totalSalePrice : totalPrice,
      requested_product_type: isCustomProduct ? 'CustomProduct' : 'Product',
      ...(couponId && { coupon_id: couponId }),
    }

    // API source code: https://goo.gl/s8hMoN
    if (isCustomProduct) {
      return {
        ...orderLine,
        requested_product_attributes: {
          description: name,
        },
      }
    }

    return orderLine
  })
}

export const getCartConversionRoute = (queryParams: ParsedUrlQuery) => {
  const cartConversionRoute = `${routes.CART_CONVERSION_LOADING.url}?${GuestHasCartParam}`

  if (queryParams.from) {
    const fromQueryParam = encodeURIComponent(queryParams.from.toString())
    return concatQueryStrings(cartConversionRoute, `?from=${fromQueryParam}`)
  }
  return cartConversionRoute
}

export const normalizeCartResponse = (cartResponse: CartResponse): Cart => {
  return {
    ...cartResponse,
    shopping_cart_lines:
      cartResponse.shopping_cart_lines?.map(flattenAndNormalizeProduct) ?? [],
    shopping_cart_lines_oos:
      cartResponse.shopping_cart_lines_oos?.map(flattenAndNormalizeProduct) ??
      [],
    promotions: normalizeCampaignsResponse(cartResponse.promotions || []),
  }
}
