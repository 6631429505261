import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { getItem, setItem } from '@/utils/localStorage'
import { getIsTokenExpired } from '@/utils/authentication/client/auth0/utils'
import { authRoutes } from '@/serverUtils/auth/constants'

interface TokenData {
  accessToken?: string
  expiresAt?: number
}

let _authData: TokenData | undefined
export const AUTH_INSTANCE_ID_KEY = 's.aiid'
const authInstanceId = uuidv4()

const _setCachedAuthData = (val?: TokenData) => {
  _authData = val
  setItem(AUTH_INSTANCE_ID_KEY, authInstanceId)
}

interface RetrieveTokenOptions {
  forceRefresh?: boolean
}
export const _retrieveAuthData = async ({
  forceRefresh = false,
}: RetrieveTokenOptions = {}) => {
  // If we have stored auth data and it was last updated by another auth instance
  // reset our local stored auth data, so that it is refetched from the server
  // Only do this if there is a "last updated by" instance id available (in case LS is blocked)
  const lastUpdatingInstance = getItem(AUTH_INSTANCE_ID_KEY)

  const isTokenExpired = _authData?.expiresAt
    ? getIsTokenExpired(_authData.expiresAt)
    : false
  if (
    forceRefresh ||
    isTokenExpired ||
    (_authData &&
      lastUpdatingInstance &&
      lastUpdatingInstance !== authInstanceId)
  ) {
    _authData = undefined
  } else if (_authData) {
    return _authData
  }
  // NOTE: The /api/auth/token segway server endpoint uses the Auth0 NextJS sdk
  // The endpoint will automatically refresh the token if expired (or expiring within 1min)
  // and respond with the newly refreshed token. The refresh token is NEVER supplied to the front-end.
  // The refresh token is kept within the encrypted cookie session.
  // Sending the ?refresh=1 query param causes the server to refresh the token, even if not expired.
  return axios
    .get<TokenData>(authRoutes.TOKEN, {
      params: {
        ...(forceRefresh && { refresh: 1 }),
      },
    })
    .then((res) => {
      _setCachedAuthData(res.data)
      return res.data
    })
}

export const _retrieveAccessToken = (options: RetrieveTokenOptions = {}) =>
  _retrieveAuthData(options).then((data) => data.accessToken)

export const _clearCachedAuthData = () => _setCachedAuthData()
