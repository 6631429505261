import { type GetServerSidePropsContext } from 'next'
import ServerCookies from 'cookies'
import { v4 as uuidv4 } from 'uuid'
import { insecureCookieOptions } from '@shared/constants/auth'
import { AJS_ANONYMOUS_ID } from '@shared/constants/segment'
import { type ServerResponse } from 'http'
import setCookie from 'set-cookie-parser'
/*
    This function is used to filter and pick the first anonymous_id created and used for server requests.
    Do not use this function directly. Must be used within the getCreateCookieAnonymousId only, since it 
    will make sure to create one if not already existent.

    Now in this function we are first trying to get all values passed in the set-cookie header to the browser.
    In case of multiple values, it is a string array otherwise a regular string, but for ease of computation we are converting it 
    to always be string array for us. The value of the element in the array will look like below:
    'ajs_anonymous_id=1d5d1fec-e5c2-41bb-bd04-f30048c5b692; path=/; expires=Sat, 20 Apr 2024 16:54:24 GMT; samesite=lax'

    So now the first step is to just find the string which has anonymous_id value, use split to just get the uuid value and then return that.
*/

export const getAnonymousIdFromResHeader = (response: ServerResponse) => {
  const setCookieValues = response.getHeader('set-cookie') ?? []
  const cookies = Array.isArray(setCookieValues)
    ? setCookieValues
    : [String(setCookieValues)]

  const parsedCookies = setCookie.parse(cookies, { map: true })

  return parsedCookies[AJS_ANONYMOUS_ID]?.value
}

/**
 * Gets the anonymous id from 'ajs_anonymous_id'. Creates a new one if it does not exist.
 * @param ssrContext
 */
export function getCreateCookieAnonymousId(
  ssrContext: GetServerSidePropsContext
) {
  const cookies = new ServerCookies(ssrContext.req, ssrContext.res)
  const anonymousId = cookies.get(AJS_ANONYMOUS_ID)
  if (anonymousId) return anonymousId

  const cookieInHeader = getAnonymousIdFromResHeader(ssrContext.res)
  if (cookieInHeader) return cookieInHeader

  const newAnonymousId = uuidv4()
  cookies.set(AJS_ANONYMOUS_ID, newAnonymousId, insecureCookieOptions)
  return newAnonymousId
}
