import { type CreditCard } from '@/services/CreditCards/types'
import {
  type PaymentMethod,
  PaymentWalletType,
} from '@/services/PaymentMethods/types'
import { type AviatorOrderV2YamlResponsePaymentMethods } from '@/graphql-types'

export type PaymentMethodLike =
  | PaymentMethod
  | AviatorOrderV2YamlResponsePaymentMethods
  | CreditCard

export const formatPaymentMethodName = (paymentMethod: PaymentMethodLike) => {
  if ('option' in paymentMethod && paymentMethod.option === 'payment_wallet') {
    return paymentMethod.paymentWalletType
  } else {
    const { brand = 'Card', display_brand, last_4_digits } = paymentMethod
    const suffix = last_4_digits ? ` ${last_4_digits}` : ''
    return `${display_brand || brand}${suffix}`
  }
}

// card expiration dates are not 0-padded on the left so
// they must be padded manually. For example, we get
// expiration dates like '2-2024', '3-2023', '12-2032', etc
export const formatCardExpDate = (card: CreditCard) => {
  const expDate = card?.exp_date
  if (!expDate) return ''

  const date = '0' + expDate
  return date.substring(date.length - 7, date.length).replace('-', '/')
}

export const getPaymentTrackingType = (paymentMethod: Maybe<PaymentMethod>) => {
  if (!paymentMethod) return 'none'

  if (paymentMethod.option === 'payment_wallet') {
    switch (paymentMethod.paymentWalletType) {
      case PaymentWalletType.ApplePay:
        return 'apple_pay'
      case PaymentWalletType.GooglePay:
        return 'google_pay'
    }
  } else {
    return 'credit_card'
  }
}
