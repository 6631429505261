import { isLocal } from '@/utils/environment'
import Bugsnag, { type Event, type BrowserConfig } from '@bugsnag/js'
import { setGlobalContextFromRoute } from '@/utils/appStart/setGlobalContextFromRoute'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { isOnServer } from '@shared/constants/util'
import { type PageRouteData } from '@/utils/setPageProps'
import { globalIgnoredMessages } from '@/lib/ThirdPartyScripts/globalIgnoredMessages'

export function errorHandler(event: Event) {
  const errorMessage = event.originalError.message
  if (errorMessage?.includes('Minified React error')) {
    // group hydration errors by page and error type e.g. Minified React error #418;
    const reactError = event.originalError.message.split(';')[0]
    const contextStr = event.context ?? 'UNKNOWN'
    event.groupingHash = `${contextStr}:${reactError}`
    event.context = `${contextStr}: React Error`
  }

  if (errorMessage === 'Request aborted') {
    event.groupingHash = 'Request aborted'
  }

  if (errorMessage === 'timeout exceeded') {
    event.groupingHash = 'Timeout exceeded'
  }

  // Chunks fail to load when a deploy happens while a tab is open
  if (/loading chunk .* failed/i.test(errorMessage)) {
    event.groupingHash = 'Chunk failure'
  }

  // React errors almost always due to Google Translate inserting font elements
  // https://github.com/facebook/react/issues/11538
  if (
    errorMessage === 'The object can not be found here.' ||
    /failed to execute.*on 'Node'/i.test(errorMessage)
  ) {
    event.groupingHash = 'React DOM error'
  }

  return globalIgnoredMessages.every((message) =>
    typeof message === 'string'
      ? message !== errorMessage
      : !message.test(errorMessage)
  )
}

let isBugsnagInitialized = false

export function startBugsnagAndSetContext(routeData: PageRouteData) {
  if (process.env.NEXT_PHASE !== 'phase-production-build' && !isOnServer()) {
    // if Bugsnag is already initialized, then we only need to handle setting
    // the route context whenever the route changes
    if (isBugsnagInitialized) {
      return setGlobalContextFromRoute(routeData)
    }

    const commonConfig: BrowserConfig = {
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
      appVersion:
        process.env.NEXT_PUBLIC_VERSION_TAG || // set app version to tag if present
        process.env.NEXT_PUBLIC_COMMIT_SHA, // otherwise use commit sha
      releaseStage: process.env.NEXT_PUBLIC_ENV,
    }
    // disable annoying output when running locally
    if (isLocal) {
      commonConfig.logger = null
      commonConfig.appVersion = 'local'
    }

    const config: BrowserConfig = {
      plugins: [new BugsnagPluginReact()],
      redactedKeys: [
        'phone',
        'email',
        'addresses',
        'creditCards',
        'preferences',
        'address_city',
        'address_id',
        'address_line_1',
        'address_line_2',
        'address_state',
        'zip',
        /^password$/i,
      ],
      onBreadcrumb: ({ metadata }) => {
        if (metadata.request?.includes('/graphql') && !metadata.operationName) {
          // ignore graphql request breadcrumbs that are logged by bugsnag
          // fetchGraphql is leaving its own custom breadcrumbs that supersede these
          // this prevents "false" successful graphql request breadcrumbs
          // due to Graphql returning a 200 even for responses with errors
          return false
        }

        const patterns = [
          // any requests to shipt domain
          /\.shipt.com\/?/,
          // any next requests, i.e. calls to our webserver
          /\/_next\//,
        ]
        // exclude any request breadcrumb that does not match request to our webserver or a shipt service
        if (
          metadata.request &&
          patterns.every((pattern) => !pattern.test(metadata.request))
        ) {
          return false
        }
      },
      onError: errorHandler,
      ...commonConfig,
    }
    isBugsnagInitialized = true
    Bugsnag.start(config)
    // sets route context on Bugsnag at initialization to capture crew and page information
    // bugs that occur immediately on app load (e.g. hydration) will then be tagged appropriately
    setGlobalContextFromRoute(routeData)
  }
}
