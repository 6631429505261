/**
 * Removes falsy values from an object. Does not check children objects
 * @param flatObject
 */

export const removeFalseyValues = (
  flatObject: Record<PropertyKey, unknown>
) => {
  return Object.fromEntries(
    Object.entries(flatObject).filter(([, value]) => !!value)
  )
}
